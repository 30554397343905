import { formatDate } from 'shared/src/date-utils';
import { formatMoney } from 'shared/src/money-utils';
import { differenceInDays, format, isAfter } from 'date-fns';
import React from 'react';
import { CombinedLineItem } from '../../store/strategy-combiner';
import { PacingSchedule } from 'shared/src/line-item-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';

export type MoneyDiff = {
  label: string;
  oldValue: number;
  newValue: number | undefined;
};

export type NumberDiff = {
  label: string;
  oldValue: number;
  newValue: number | undefined;
};

export type DateDiff = {
  label: string;
  oldValue: Date;
  newValue: Date | undefined;
};

export type PacingDetailsDiff = {
  oldValue: PacingSchedule | null;
  newValue: PacingSchedule | null | undefined;
};

export function LineItemDiffs({ lineItem }: { lineItem: CombinedLineItem }) {
  if (lineItem.state.type !== 'update') return null;
  const { dirty } = lineItem.state;

  return (
    <>
      {dirty.is_deleted && dirty.is_deleted.new === true && <DeletedChange />}
      {dirty.name && (
        <StringChange label="Name" change={{ old: dirty.name.old, new: dirty.name.new }} />
      )}
      {dirty.price && (
        <MoneyChange label="Price" oldValue={dirty.price.old} newValue={dirty.price.new} />
      )}
      {dirty.channel && (
        <StringChange
          label="Channel"
          change={{ old: dirty.channel.old.name, new: dirty.channel.new?.name }}
        />
      )}
      {dirty.tactic && (
        <StringChange
          label="Tactic"
          change={{ old: dirty.tactic.old.name, new: dirty.tactic.new?.name }}
        />
      )}
      {dirty.unit_price_type && (
        <StringChange
          label="Unit Price Type"
          change={{ old: dirty.unit_price_type.old.name, new: dirty.unit_price_type.new?.name }}
        />
      )}
      {dirty.geo && (
        <StringChange label="Geo" change={{ old: dirty.geo.old, new: dirty.geo.new }} />
      )}
      {dirty.targeting && (
        <StringChange
          label="Targeting"
          change={{ old: dirty.targeting.old, new: dirty.targeting.new }}
        />
      )}
      {dirty.start_date && (
        <DateChange
          label="Start Date"
          oldValue={dirty.start_date.old}
          newValue={dirty.start_date.new}
        />
      )}
      {dirty.end_date && (
        <DateChange label="End Date" oldValue={dirty.end_date.old} newValue={dirty.end_date.new} />
      )}
      {dirty.unit_price && (
        <MoneyChange
          label="Unit Price"
          oldValue={dirty.unit_price.old}
          newValue={dirty.unit_price.new}
        />
      )}
      {dirty.target_margin && (
        <NumberChangeCmp
          label="Target Margin"
          oldValue={dirty.target_margin.old}
          newValue={dirty.target_margin.new}
        />
      )}
      {dirty.audience && (
        <StringChange
          label="Audience"
          change={{ old: dirty.audience.old, new: dirty.audience.new }}
        />
      )}
      {dirty.ad_formats && (
        <StringChange
          label="Ad Formats"
          change={{ old: dirty.ad_formats.old, new: dirty.ad_formats.new }}
        />
      )}
      {dirty.pacing_type && (
        <StringChange
          label="Pacing Type"
          change={{ old: dirty.pacing_type.old, new: dirty.pacing_type.new }}
        />
      )}
      {dirty.pacing_details && (
        <PacingDetailsCmp oldValue={dirty.pacing_details.old} newValue={dirty.pacing_details.new} />
      )}
      {dirty.media_traders && (
        <StringChange
          label={'Media Traders'}
          change={{
            old: dirty.media_traders.old.map(u => u.name).join(','),
            new: dirty.media_traders.new?.map(u => u.name).join(',')
          }}
        />
      )}
      {dirty.media_platforms && (
        <StringChange
          label="Media Platforms"
          change={{
            old: dirty.media_platforms.old.map(p => p.name).join(','),
            new: dirty.media_platforms.new?.map(p => p.name).join(',')
          }}
        />
      )}
    </>
  );
}

function DeletedChange() {
  return (
    <div className="flex items-center">
      <FontAwesomeIcon icon={faTrashCan} className="mr-2 text-red-500" />
      <div className="text-red-500">Deleted</div>
    </div>
  );
}

function MoneyChange({ oldValue, newValue, label }: MoneyDiff) {
  if (newValue == null) return null;
  const isBigger = newValue > oldValue;
  return (
    <div className="mb-1 ml-2 flex items-center font-light">
      <div>
        {isBigger ? 'Increased' : 'Decreased'}
        {label}
      </div>
      <div className={`${isBigger ? 'text-green-700' : 'text-red-500'} ml-2 text-sm`}>
        {isBigger ? '+' : '-'}
        {formatMoney(Math.abs((newValue || 0) - oldValue))}
      </div>
      <div className="flex-1" />
      <div>
        <span className="text-gray-400">{formatMoney(oldValue)}</span> →{' '}
        <span className="text-gray-700">{formatMoney(newValue)}</span>
      </div>
    </div>
  );
}

function DateChange({ label, oldValue, newValue }: DateDiff) {
  if (newValue == null) return null;
  const delayed = isAfter(newValue, oldValue);
  const difference = differenceInDays(oldValue, newValue);
  return (
    <div className="mb-1 ml-2 flex items-center font-light">
      <div>
        {delayed ? 'Delayed' : 'Moved up'} {label}
      </div>
      <div className={`${!delayed ? 'text-green-700' : 'text-red-500'} ml-2 text-sm`}>
        {!delayed && '+'}
        {difference} days
      </div>
      <div className="flex-1" />
      <div>
        <span className="text-gray-400">{format(oldValue, 'MMM do yyyy')}</span> →{' '}
        <span className="text-gray-700">{format(newValue, 'MMM do yyyy')}</span>
      </div>
    </div>
  );
}

type StringChangeProps = { label: string; change: { old: string; new: string | undefined } };

function StringChange({ label, change }: StringChangeProps) {
  return (
    <div>
      <div>{label}</div>
      <div>
        <span className="text-gray-400">{change.old}</span> →{' '}
        <span className="text-gray-700">{change.new || ''}</span>
      </div>
    </div>
  );
}

function NumberChangeCmp({ label, oldValue, newValue }: NumberDiff) {
  return (
    <div className="mb-1 ml-2 flex font-light">
      <div className="mr-2">{label}:</div>
      <div>
        <span className="text-gray-400">{oldValue}</span> →{' '}
        <span className="text-gray-700">{newValue}</span>
      </div>
    </div>
  );
}

function PacingDetailsCmp({ oldValue, newValue }: PacingDetailsDiff) {
  return (
    <div>
      <div>Pacing Details</div>
      <div className="flex text-sm">
        {oldValue && (
          <div className="flex flex-col text-gray-400">
            {oldValue.blocks.map((block, idx) => (
              <div key={idx} className="flex">
                <div className="mr-2">
                  {formatDate(block.start_date)} to {formatDate(block.end_date)}:
                </div>
                <div>{formatMoney(block.price)}</div>
              </div>
            ))}
          </div>
        )}
        {newValue && oldValue && <div className="flex flex-1 items-center justify-center">→</div>}
        {newValue && (
          <div className="flex flex-col text-gray-700">
            {newValue.blocks.map((block, idx) => (
              <div key={idx} className="flex">
                <div className="mr-2">
                  {formatDate(block.start_date)} to {formatDate(block.end_date)}:
                </div>
                <div>{formatMoney(block.price)}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
