import React from 'react';
import { Campaign } from 'shared/src/types';
import { Link, Route, Routes } from 'react-router-dom';
import { PendingChangesButton } from '../pending-changes/pending-changes-button';
import { StrategyChangelog } from './strategy-changelog';
import { StrategyOverview } from './strategy-overview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/pro-light-svg-icons';
import { useStrategyContext } from '../line-items/strategy-context';
import { NavItem } from '../../components/navitem';
import { TopSection } from '../../components/layout';
import { SyncStrategyButton } from './sync-strategy-button';

export function StrategyPage() {
  const { campaign, refetch } = useStrategyContext();
  return (
    <div className="flex w-full flex-col">
      <TopSection>
        <Breadcrumbs campaign={campaign} />
        <div className="flex-1" />
        <div className="flex items-end justify-between">
          <div className="flex items-end">
            <NavItem to="performance" label="Overview" />
            <NavItem to="changelog" label="Changelog" />
          </div>
          <div className="ml-auto flex space-x-4 pb-2">
            {campaign && <SyncStrategyButton zohoCampaignId={campaign?.id} refetch={refetch} />}
          </div>
        </div>
      </TopSection>
      <PendingChangesButton />
      <Routes>
        <Route path="performance" element={<StrategyOverview />} />
        <Route path="changelog" element={<StrategyChangelog />} />
      </Routes>
    </div>
  );
}

function Breadcrumbs({ campaign }: { campaign: Campaign | undefined }) {
  return (
    <div className="mt-6 flex flex-col">
      <div className="flex">
        <Link className="mr-1 flex items-center hover:underline" to="/campaigns">
          <FontAwesomeIcon icon={faHouse} className="mr-1" />
          <div>All Campaigns</div>
        </Link>
        <div className="mr-1">/</div>
      </div>
      <div className="mt-2 text-2xl font-bold">{campaign ? campaign.Deal_Name : '-'}</div>
    </div>
  );
}
