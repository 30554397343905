import { clsx } from 'clsx';
import React from 'react';

type MetricItemProps = {
  name: string;
  noBorder?: boolean;
  value?: string;
};

export function MetricItem({ name, value, noBorder = false }: MetricItemProps) {
  return (
    <div
      className={clsx(
        'mb-4 mr-4 flex-1 whitespace-nowrap pr-4',
        noBorder ? '' : 'border-r-[1px] border-gray-200'
      )}>
      <div className="mb-2 text-sm font-light">{name}</div>
      {value ? (
        <div className="text-xl">{value}</div>
      ) : (
        <div className="text-xl text-gray-400">--</div>
      )}
    </div>
  );
}
