import { HeroMetricType } from '../../components/hero-metrics';
import { MediaBuyMetric } from 'shared/src/metrics-types';
import { CombinedLineItem, CombinedMediaBuy } from '../../store/strategy-combiner';
import { calcEstimatedUnits, calcMediaBudget } from 'shared/src/line-item-utils';
import { differenceInMilliseconds, isAfter, isBefore } from 'date-fns';

export function generateMediaBuyMetrics(
  data: MediaBuyMetric[],
  lineItem: CombinedLineItem,
  mediaBuy: CombinedMediaBuy
): HeroMetricType[] {
  const delivered = unitsDelivered(data, mediaBuy);
  const onPaceTarget = calcOnPaceTarget(lineItem, mediaBuy);
  const deliveryPacing = delivered / onPaceTarget;

  return [
    {
      primary: { name: 'Delivery Pacing', value: deliveryPacing.toLocaleString() },
      other: [
        { name: 'Impressions delivered', value: delivered.toLocaleString() },
        { name: 'On-pace target', value: Math.round(onPaceTarget).toLocaleString() }
      ]
    },
    {
      primary: { name: 'Spend Pacing', value: undefined },
      other: [
        { name: 'Media spend', value: undefined },
        { name: 'On-pace spend', value: undefined }
      ]
    },
    {
      primary: { name: 'Margin Performance', value: undefined },
      other: [
        { name: 'Margin', value: undefined },
        { name: 'Target Margin', value: undefined }
      ]
    }
  ];
}

function unitsDelivered(data: MediaBuyMetric[], mediaBuy: CombinedMediaBuy) {
  return data
    .filter(d => d.MEDIA_BUY_ID === mediaBuy.id)
    .map(d => d.ACTUAL_DELIVERED_UNITS)
    .reduce((a, b) => a + b, 0);
}

function calcOnPaceTarget(lineItem: CombinedLineItem, mediaBuy: CombinedMediaBuy) {
  const totalEstimatedUnits = calcEstimatedUnits(lineItem);
  const totalBudget = calcMediaBudget(lineItem);
  if (
    !totalBudget ||
    !totalEstimatedUnits ||
    !mediaBuy.budget ||
    !mediaBuy.start_date ||
    !mediaBuy.end_date
  )
    return 0;

  const totalUnitsRequired = totalEstimatedUnits * (mediaBuy.budget / totalBudget);

  return (
    totalUnitsRequired * calcElapsedProportion(mediaBuy.start_date, mediaBuy.end_date, new Date())
  );
}

function calcElapsedProportion(startDate: Date, endDate: Date, currentDate: Date): number {
  // Ensure the currentDate is between startDate and endDate

  if (isBefore(currentDate, startDate)) return 0;
  if (isAfter(currentDate, endDate)) return 1;

  const totalDuration = differenceInMilliseconds(endDate, startDate);
  const elapsedDuration = differenceInMilliseconds(currentDate, startDate);

  // Calculate the percentage elapsed
  return elapsedDuration / totalDuration;
}
