import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { client } from '../../utils/trpc-client';
import { formatMoney } from 'shared/src/money-utils';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  ModuleRegistry,
  StatusPanelDef
} from '@ag-grid-community/core';
import { AgGridReact, CustomCellRendererProps } from '@ag-grid-community/react';
import { ZohoCampaign, zohoCampaignStatusValues } from 'shared/src/zoho-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../components/button';
import { AccountManagerFilter, ClientSuccessDirectorFilter } from './account-manager-filter';
import { useNavigate } from 'react-router-dom';
import { CampaignsTableFilter } from './campaign-filter-types';
import { CampaignTotalStatusPanel } from './campaign-totals-status-panel';

ModuleRegistry.registerModules([
  InfiniteRowModelModule,
  MenuModule,
  SetFilterModule,
  StatusBarModule
]);

const filterParams = { values: zohoCampaignStatusValues };

export function ZohoCampaigns() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>('');
  const [gridApi, setGridApi] = useState<GridApi<ZohoCampaign> | null>(null);
  const [columnDefs] = useState<ColDef<ZohoCampaign>[]>([
    {
      field: 'id',
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value !== undefined) {
          return props.value;
        } else {
          return <FontAwesomeIcon icon={faSpinner} className="animate-rotate text-blue-500" />;
        }
      },
      suppressHeaderMenuButton: true
    },
    {
      field: 'Deal_Name',
      headerName: 'Name',
      suppressHeaderMenuButton: true
    },
    {
      field: 'Account_Name.name',
      headerName: 'Account',
      suppressHeaderMenuButton: true
    },
    {
      field: 'Budget',
      valueFormatter: ({ value }) => (value ? formatMoney(value) : ''),
      suppressHeaderMenuButton: true,
      filter: 'agNumberColumnFilter',
      filterParams: {
        allowedCharPattern: '\\d\\-\\,',
        filterOptions: ['equals', 'greaterThan', 'lessThan'],
        maxNumConditions: 2
      }
    },
    {
      field: 'Stage',
      filter: 'agSetColumnFilter',
      filterParams,
      comparator: (a, b) =>
        zohoCampaignStatusValues.indexOf(a) - zohoCampaignStatusValues.indexOf(b),
      suppressHeaderMenuButton: true
    },
    {
      field: 'Lead_Account_Owner.name',
      headerName: 'Lead Account Owner',
      suppressHeaderMenuButton: true,
      filter: AccountManagerFilter
    },
    {
      field: 'Sales_Rep.name',
      headerName: 'Client Success Director',
      suppressHeaderMenuButton: true,
      filter: ClientSuccessDirectorFilter
    }
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: false
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('datasource', {
        getRows: async params => {
          const parsed = CampaignsTableFilter.safeParse(params.filterModel);
          if (!parsed.success) {
            console.error('Failed to parse campaigns filter model', parsed.error);
            return;
          }
          const filterModel = parsed.data;
          if (filterModel.Stage && filterModel.Stage.values.length === 0) {
            params.successCallback([], 0);
            return;
          }
          const { data, total_count } = await client.campaigns.query({
            page: Math.ceil(params.endRow / 50),
            amName: filterModel['Lead_Account_Owner.name']?.value,
            csdName: filterModel['Sales_Rep.name']?.value,
            price: filterModel['Price'],
            ...(filterModel.Stage && { status: params.filterModel.Stage.values }),
            ...(searchText && { search: searchText })
          });
          params.successCallback(data, total_count);
        }
      });
    }
  }, [gridApi, searchText]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.api.setFilterModel(defaultFilters());
    setGridApi(params.api);
  }, []);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [{ statusPanel: CampaignTotalStatusPanel }]
    };
  }, []);

  return (
    <div className="flex h-full w-full flex-col p-12">
      <div className="flex items-center justify-between">
        <div className="my-8 text-2xl font-extralight">All Campaigns</div>
        <SearchBar submitSearch={setSearchText} />
      </div>
      <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowBuffer={0}
          rowSelection={'multiple'}
          rowModelType={'infinite'}
          cacheBlockSize={50}
          cacheOverflowSize={2}
          isExternalFilterPresent={() => true}
          infiniteInitialRowCount={100}
          maxBlocksInCache={10}
          onGridReady={onGridReady}
          statusBar={statusBar}
          onRowClicked={event =>
            event.data?.id && navigate(`/campaigns/${event.data.id}/strategy/performance`)
          }
        />
      </div>
    </div>
  );
}

function SearchBar({ submitSearch }: { submitSearch: (searchText: string) => unknown }) {
  const [searchText, setSearchText] = useState<string>('');

  function onSubmit() {
    submitSearch(searchText);
  }

  return (
    <form id="create-media-buy" className="flex" onSubmit={e => e.preventDefault()}>
      <div className="relative mr-2 w-[600px] rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <FontAwesomeIcon icon={faSearch} className="h-5 w-5 text-gray-400" />
        </div>
        <input
          id="search"
          name="search"
          value={searchText}
          onKeyDown={e => e.key === 'Enter' && onSubmit()}
          onChange={e => setSearchText(e.target.value)}
          type="text"
          placeholder="Search by Account Name, Campaign Name, Lead AM, CSD"
          className="block w-full rounded-md border-0 py-3 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
        />
      </div>
      <Button onClick={onSubmit} disabled={false}>
        <div className="w-24">Search</div>
      </Button>
    </form>
  );
}

function defaultFilters(): CampaignsTableFilter {
  return {
    Stage: {
      values: [
        'Potential Opportunity',
        'New',
        'Media Campaign Details',
        'Avails/Plan Development',
        'Proposal Sent',
        'Book the Campaign',
        'Traffic & Onboard',
        'Pixel Setup',
        'Potential Opportunity',
        'Avails/Plan Development',
        'Campaign is Live',
        'Campaign is Paused',
        'Campaign Management & Reporting',
        'Renew Campaign',
        'Campaign Approved to Launch',
        'Pixel Check',
        'Potential'
      ],
      filterType: 'set'
    }
  };
}
