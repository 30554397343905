import { ZohoCampaign } from 'shared/src/zoho-types';
import { PartialLineItem } from 'shared/src/line-item-types';

export function generatePlatformLineItemName(
  zohoCampaign: ZohoCampaign,
  lineItem: PartialLineItem,
  suffix?: string
): string {
  // TODO [ac] confirm how tactic is treated in cases where there are non-alphanum characters i.e. Meta(FB/IG), X (Twitter) etc
  return `${zohoCampaign.Deal_Number?.replaceAll(' ', '').trim() || 'N/A'}|FNMT|${zohoCampaign.Deal_Name.replace(' ', '_')}|${lineItem.tactic?.name}${suffix ? `|${suffix}` : ''}`;
}
