import React from 'react';
import { trpc } from '../../utils/trpc-client';
import { differenceInDays, format, isAfter } from 'date-fns';
import {
  Changeset,
  DateChange,
  DBChangeset,
  NumberChange,
  StringChange,
  UserListChange
} from 'shared/src/changeset-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-regular-svg-icons';
import {
  getNewLineItems,
  getNewMediaBuys,
  getUpdatedLineItems,
  getUpdatedMediaBuys
} from './strategy-changelog-utils';
import {
  HistoryLineItemUpdate,
  HistoryMediaBuyUpdate,
  NewHistoryLineItem,
  NewHistoryMediaBuy
} from '../line-items/new-line-item';
import { Link } from 'react-router-dom';
import { formatMoney } from 'shared/src/money-utils';
import { useStrategyContext } from '../line-items/strategy-context';
import { CombinedStrategy } from '../../store/strategy-combiner';

export function StrategyChangelog() {
  const { strategy } = useStrategyContext();
  const changelog = trpc.strategyChangelog.useQuery({ strategy_id: strategy.id });

  if (changelog.isLoading || !changelog.data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mt-8">
      {changelog.data
        .filter(changeset => !noChanges(changeset))
        .map(changeset =>
          changeset.source === 'user' ? (
            <UserChangeset key={changeset.changeset_id} changeset={changeset} strategy={strategy} />
          ) : (
            <SystemChangeset
              key={changeset.changeset_id}
              changeset={changeset}
              strategy={strategy}
            />
          )
        )}
    </div>
  );
}

type UserChangesetProps = {
  changeset: Changeset;
  strategy: CombinedStrategy;
};

function UserChangeset({ changeset, strategy }: UserChangesetProps) {
  const newLineItems = getNewLineItems(changeset);
  const updatedLineItems = getUpdatedLineItems(changeset, strategy);
  const newMediaBuys = getNewMediaBuys(changeset);

  return (
    <div className="mb-8 mr-3 flex flex-col p-2 pb-4 pl-4">
      <div className="flex items-center">
        <div className="mr-4 font-bold">
          {format(changeset.created_at, 'MMM do yyyy hh:mm:ss a')}
        </div>
        <div className="text-gray-400">{changeset.user_name} updated the Media Strategy</div>
      </div>
      <div className="my-4">
        <div className="mb-2 flex items-center">
          <div className="mr-2 text-sm font-light">Comment</div>
          <FontAwesomeIcon icon={faMessage} />
        </div>
        <div className="w-1/2 rounded border-[1px] p-4 italic">{changeset.description}</div>
      </div>
      {newLineItems.length > 0 && (
        <div>
          Added {newLineItems.length} new Line Item{newLineItems.length > 1 ? 's' : ''}
        </div>
      )}
      {newLineItems.map((lineItem, idx) => (
        <NewHistoryLineItem key={idx} lineItem={lineItem} />
      ))}
      {updatedLineItems.map((lineItem, idx) => (
        <UpdatedHistoryLineItem key={idx} lineItem={lineItem} />
      ))}
      {newMediaBuys.length > 0 && (
        <div>
          Added {newMediaBuys.length} new Media Buy{newMediaBuys.length > 1 ? 's' : ''}
        </div>
      )}
      {newMediaBuys.map((mediaBuy, idx) => (
        <NewHistoryMediaBuy key={idx} mediaBuy={mediaBuy} />
      ))}
    </div>
  );
}

function noChanges(changeset: DBChangeset) {
  return changeset.changes.line_items == null && changeset.changes.media_buys == null;
}

type SystemChangesetProps = {
  changeset: DBChangeset;
  strategy: CombinedStrategy;
};

function SystemChangeset({ changeset, strategy }: SystemChangesetProps) {
  const updatedMediaBuys = getUpdatedMediaBuys(changeset, strategy);

  return (
    <div className="mb-8 mr-3 flex flex-col rounded bg-white p-2 pb-4 pl-4 shadow">
      <div className="mb-2 flex items-center">
        <div className="mr-4 font-bold">
          {format(changeset.created_at, 'MMM do yyyy hh:mm:ss a')}
        </div>
        <div className="text-gray-400">Media Platform Change</div>
      </div>
      {updatedMediaBuys.map((mediaBuy, idx) => (
        <UpdatedMediaBuyItem key={idx} mediaBuy={mediaBuy} />
      ))}
    </div>
  );
}

function UpdatedHistoryLineItem({ lineItem }: { lineItem: HistoryLineItemUpdate }) {
  const numChanges = Object.values(lineItem.delta).filter(v => v != null).length;
  return (
    <div className="mb-2 bg-gray-100 p-2">
      <div>
        {numChanges} Update{numChanges > 1 ? 's' : ''} to{' '}
        <Link className="text-blue-500 hover:underline" to={`../lineitems/${lineItem.lineItem.id}`}>
          {lineItem.lineItem.name}
        </Link>{' '}
        Line Item
      </div>
      {lineItem.delta.is_deleted && <DeletedCmp />}
      {lineItem.delta.name && <StringChangeCmp change={lineItem.delta.name} label="Changed Name" />}
      {lineItem.delta.channel_id && (
        <NumberChangeCmp change={lineItem.delta.channel_id} label="Changed Channel" />
      )}
      {lineItem.delta.tactic_id && (
        <NumberChangeCmp change={lineItem.delta.tactic_id} label="Changed Tactic" />
      )}
      {lineItem.delta.unit_price_type_id && (
        <NumberChangeCmp
          change={lineItem.delta.unit_price_type_id}
          label="Changed Unit Price Type"
        />
      )}
      {lineItem.delta.geo && <StringChangeCmp change={lineItem.delta.geo} label="Changed Geo" />}
      {lineItem.delta.audience && (
        <StringChangeCmp change={lineItem.delta.audience} label="Changed Audience" />
      )}

      {lineItem.delta.media_traders && (
        <UserListChangeCmp change={lineItem.delta.media_traders} label="Changed Media Traders" />
      )}
      {lineItem.delta.price && <PriceChangeCmp change={lineItem.delta.price} />}
      {lineItem.delta.start_date && (
        <DateChangeCmp change={lineItem.delta.start_date} label="Start Date" />
      )}
      {lineItem.delta.end_date && (
        <DateChangeCmp change={lineItem.delta.end_date} label="End Date" />
      )}
    </div>
  );
}

function UpdatedMediaBuyItem({ mediaBuy }: { mediaBuy: HistoryMediaBuyUpdate }) {
  const numChanges = Object.values(mediaBuy.delta).filter(v => v != null).length;
  return (
    <div className="mb-2 bg-gray-100 p-2">
      <div>
        {numChanges} Update{numChanges > 1 ? 's' : ''} to Media Buy {mediaBuy.mediaBuy.name}
      </div>
      {mediaBuy.delta.budget && <PriceChangeCmp change={mediaBuy.delta.budget} />}
      {mediaBuy.delta.start_date && (
        <DateChangeCmp change={mediaBuy.delta.start_date} label="Start Date" />
      )}
      {mediaBuy.delta.end_date && (
        <DateChangeCmp change={mediaBuy.delta.end_date} label="End Date" />
      )}
    </div>
  );
}

function PriceChangeCmp({ change }: { change: NumberChange }) {
  const isBigger = change.new > (change.old || 0);
  return (
    <div className="mb-1 ml-2 flex items-center pr-2 font-light">
      <div>{isBigger ? 'Increased' : 'Decreased'} Budget</div>
      <div className={`${isBigger ? 'text-green-700' : 'text-red-500'} ml-2 text-sm`}>
        {isBigger ? '+' : '-'}
        {formatMoney(Math.abs(change.new - (change.old || 0)))}
      </div>
      <div className="flex-1" />
      <div>
        <span className="text-gray-400">{formatMoney(change.old)}</span> →{' '}
        <span className="text-gray-700">{formatMoney(change.new)}</span>
      </div>
    </div>
  );
}

function StringChangeCmp({ change, label }: { change: StringChange; label: string }) {
  return (
    <div className="mb-1 ml-2 flex items-center pr-2 font-light">
      <div>{label}</div>
      <div className="flex-1" />
      <div>
        <span className="text-gray-400">{change.old}</span> →{' '}
        <span className="text-gray-700">{change.new}</span>
      </div>
    </div>
  );
}

function DeletedCmp() {
  return (
    <div className="mb-1 ml-2 flex items-center pr-2 font-light">
      <div>Line Item was deleted</div>
    </div>
  );
}

function NumberChangeCmp({ change, label }: { change: NumberChange; label: string }) {
  return (
    <div className="mb-1 ml-2 flex items-center pr-2 font-light">
      <div>{label}</div>
      <div className="flex-1" />
      <div>
        <span className="text-gray-400">{change.old}</span> →{' '}
        <span className="text-gray-700">{change.new}</span>
      </div>
    </div>
  );
}

function UserListChangeCmp({ change, label }: { change: UserListChange; label: string }) {
  if (!change.old) return null;
  return (
    <div className="mb-1 ml-2 flex items-center pr-2 font-light">
      <div>{label}</div>
      <div className="flex-1" />
      <div>
        <span className="text-gray-400">{change.old.map(u => u.name).join(', ')}</span> →{' '}
        <span className="text-gray-700">{change.new.map(u => u.name).join(', ')}</span>
      </div>
    </div>
  );
}

function DateChangeCmp({ change, label }: { change: DateChange; label: string }) {
  if (!change.old) return null;
  const delayed = isAfter(change.new, change.old);
  const difference = differenceInDays(change.old, change.new);
  return (
    <div className="mb-1 ml-2 flex items-center pr-2 font-light">
      <div>
        {delayed ? 'Delayed' : 'Moved up'} {label}
      </div>
      <div className={`${!delayed ? 'text-green-700' : 'text-red-500'} ml-2 text-sm`}>
        {!delayed && '+'}
        {difference} days
      </div>
      <div className="flex-1" />
      <div>
        <span className="text-gray-400">{format(change.old, 'MMM do yyyy')}</span> →{' '}
        <span className="text-gray-700">{format(change.new, 'MMM do yyyy')}</span>
      </div>
    </div>
  );
}
