import {
  addDays,
  differenceInDays,
  eachDayOfInterval,
  isSameDay,
  isWithinInterval
} from 'date-fns';
import { CombinedLineItem } from '../../store/strategy-combiner';
import { PacingBlock, PacingSchedule } from 'shared/src/line-item-types';

export type Day = { day: Date; cumulativeTotal: number };

export function getCumulativeSpend(startDate: Date, endDate: Date, blocks: PacingBlock[]): Day[] {
  const days = eachDayOfInterval({ start: startDate, end: endDate });
  const dailyTotals = days.map(day => ({
    day,
    dailySpend: blocks
      .map(block =>
        isWithinInterval(day, { start: block.start_date, end: block.end_date })
          ? dailySpend(block)
          : 0
      )
      .reduce((a, b) => a + b, 0)
  }));

  return dailyTotals.map(({ day }, idx, arr) => ({
    day,
    cumulativeTotal: arr.slice(0, idx + 1).reduce((acc, curr) => acc + curr.dailySpend, 0)
  }));
}

function dailySpend(segment: PacingBlock): number {
  const diff = differenceInDays(segment.end_date, segment.start_date) + 1;
  return diff === 0 ? segment.price : segment.price / diff;
}

export function addBlock(segment: PacingBlock, currentSegments: PacingBlock[]): PacingSchedule {
  let newBlocks = [...currentSegments];

  // Add the new segment
  newBlocks.push(segment);

  // Sort segments by start date
  newBlocks.sort((a, b) => a.start_date.getTime() - b.start_date.getTime());

  return { blocks: newBlocks };
}

export function createNextBlock(lineItem: CombinedLineItem): PacingBlock | null {
  const { start_date, end_date, pacing_details } = lineItem;
  if (!start_date || !end_date) return null;
  if (!pacing_details?.blocks || pacing_details.blocks.length === 0) {
    return { start_date, end_date, price: 0 };
  }
  const { blocks } = pacing_details;

  const last = blocks[blocks.length - 1];
  return {
    start_date: isSameDay(last.end_date, end_date) ? end_date : addDays(last.end_date, 1),
    end_date,
    price: 0
  };
}

export function isLineItemValid(lineItem: CombinedLineItem) {
  if (lineItem.pacing_type !== 'custom') return true;
  if (!lineItem.pacing_details) return false;
  return (
    arePacingIntervalsValid(lineItem.pacing_details) &&
    isPacingPriceValid(lineItem.pacing_details, lineItem.price || 0)
  );
}

export function arePacingIntervalsValid(pacingDetails: PacingSchedule) {
  return pacingDetails.blocks.every((_, idx) => {
    const overlapping = isOverlapping(pacingDetails.blocks, idx);
    return !overlapping.start_date && !overlapping.end_date;
  });
}

export function isPacingPriceValid(pacingSchedule: PacingSchedule, price: number) {
  const totalPrice = pacingSchedule.blocks.reduce((acc, segment) => acc + segment.price, 0);
  return totalPrice === price;
}

export function isOverlapping(blocks: PacingBlock[], idx: number) {
  if (blocks.length <= 1) return { start_date: false, end_date: false };

  const current = blocks[idx];
  const others = blocks.filter((_, i) => i !== idx);
  return {
    start_date: others.some(block =>
      isWithinInterval(current.start_date, { start: block.start_date, end: block.end_date })
    ),
    end_date: others.some(block =>
      isWithinInterval(current.end_date, { start: block.start_date, end: block.end_date })
    )
  };
}
