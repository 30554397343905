import { StateStorage } from 'zustand/middleware';

const items: Record<string, any> = {};

export function isTestMode() {
  return typeof localStorage === 'undefined';
}

export function createTestStorage(): StateStorage {
  return {
    getItem: (name: string) => {
      return items[name] ? (JSON.parse(items[name]) as any) : null;
    },
    setItem: (name: string, value: unknown) => {
      items[name] = JSON.stringify(value);
    },
    removeItem: (name: string) => {
      delete items[name];
    }
  };
}
