import React from 'react';
import { Button } from '../../components/button';
import { useStore } from '../../store/store';
import { v4 } from 'uuid';
import { Campaign } from 'shared/src/types';
import { UTCDate } from '@date-fns/utc';
import { campaignHasBudget } from '../strategy/allowed-actions-utils';

type Props = {
  strategyId: string;
  campaign: Campaign | undefined;
};

export function NewLineItemButton({ strategyId, campaign }: Props) {
  const addLineItem = useStore(state => state.addLineItem);

  async function createLineItem() {
    const start_date = campaign?.Flight_Date ? new UTCDate(campaign.Flight_Date) : new UTCDate();
    const end_date = campaign?.End_Date ? new UTCDate(campaign.End_Date) : new UTCDate();

    await addLineItem({
      id: v4(),
      description: 'Test',
      strategy_id: strategyId,
      price: campaign?.Budget || 0,
      pacing_type: 'lifetime',
      pacing_details: null,
      audience: '',
      targeting: '',
      ad_formats: '',
      start_date,
      end_date,
      media_traders: [],
      media_platforms: [],
      is_deleted: false
    });
  }

  return (
    <>
      <Button disabled={!campaignHasBudget(campaign)} onClick={createLineItem}>
        <div className="mr-2">New Line Item</div>
      </Button>
    </>
  );
}
