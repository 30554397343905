import * as React from 'react';
import { useState } from 'react';
import './index.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './auth/protected-route';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink } from '@trpc/client';
import { trpc } from './utils/trpc-client';
import { Login } from './features/auth/oauth-login';
import { CampaignStrategyPage } from './features/strategy/campaign-strategy-page';
import superjson from 'superjson';
import { ZohoCampaigns } from './features/zoho-campaigns/zoho-campaigns';
import { PostHogProvider } from 'posthog-js/react';
import { posthogApiKey, posthogConfig } from './utils/env-utils';
import { ChannelRatesContextProvider } from './features/strategy/channel-rates-context';
import { Sidebar } from './components/sidebar';

export function App() {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() => {
    return trpc.createClient({
      links: [httpLink({ url: '/api', transformer: superjson })]
    });
  });

  return (
    <PostHogProvider apiKey={posthogApiKey} options={posthogConfig}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/*"
                element={
                  <ProtectedRoute>
                    <InnerApp />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </trpc.Provider>
    </PostHogProvider>
  );
}

function InnerApp() {
  const { data: channels, isLoading: channelsIsLoading } = trpc.channels.useQuery();

  if (channelsIsLoading || !channels) {
    return <div>Loading....</div>;
  }

  return (
    <ChannelRatesContextProvider channels={channels}>
      <div className="flex h-lvh w-full bg-gray-50">
        <Sidebar />
        <Routes>
          <Route path="/campaigns" element={<ZohoCampaigns />} />
          <Route path="/campaigns/:zohoCampaignId/*" element={<CampaignStrategyPage />} />
          <Route path="*" element={<Navigate to="/campaigns" />} />
        </Routes>
      </div>
    </ChannelRatesContextProvider>
  );
}
