import { NavLink } from 'react-router-dom';
import { clsx } from 'clsx';
import React from 'react';

export function NavItem({ to, label }: { to: string; label: string }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        clsx(
          'flex border-b-2 px-5 py-2 text-sm',
          isActive
            ? 'border-[#3658f9] bg-[#e1e8ff] text-[#3658f9]'
            : 'border-transparent text-[#637381]'
        )
      }>
      <div>{label}</div>
    </NavLink>
  );
}
