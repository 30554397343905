import React from 'react';

type Metric = {
  name: string;
  value: string | undefined;
};

export type HeroMetricType = {
  primary: Metric;
  other: Metric[];
};

type Props = {
  heroMetrics: HeroMetricType[];
};

export function HeroMetrics({ heroMetrics }: Props) {
  return (
    <div className="mb-2 flex justify-between gap-2 rounded-lg bg-gray-200 p-2">
      {heroMetrics.map(metric => (
        <HeroMetric key={metric.primary.name} heroMetric={metric} />
      ))}
    </div>
  );
}

function HeroMetric({ heroMetric }: { heroMetric: HeroMetricType }) {
  const { primary, other } = heroMetric;
  return (
    <div className="flex-1 justify-center rounded-lg bg-gray-100 px-2 py-1.5 text-center">
      <div className="text-base font-semibold">{primary.name}</div>
      <div className="text-3xl font-semibold">{primary.value || '--'}</div>
      <div className="mt-1">
        {other.map(otherMetric => (
          <div
            key={otherMetric.name}
            className="text-xs text-gray-500">{`${otherMetric.value || '--'} ${otherMetric.name}`}</div>
        ))}
      </div>
    </div>
  );
}
