export type WhitelistedZohoCampaign = {
  zohoCampaignId: string;
  beeswaxAdvertiserId: number;
};

const whitelistedZohoCampaigns: WhitelistedZohoCampaign[] = [
  { zohoCampaignId: '2461504000285714719', beeswaxAdvertiserId: 80 }, // Matthews Kia
  { zohoCampaignId: '2461504000223097009', beeswaxAdvertiserId: 108 }, // Five Peaks, LLC
  { zohoCampaignId: '2461504000222356011', beeswaxAdvertiserId: 460 }, // Metropolitan Theatres
  { zohoCampaignId: '2461504000293832176', beeswaxAdvertiserId: 627 }, // ExxonMobil
  { zohoCampaignId: '2461504000289972001', beeswaxAdvertiserId: 628 }, // Vista Senior Living Management
  { zohoCampaignId: '2461504000289972555', beeswaxAdvertiserId: 628 }, // Vista Senior Living Management
  { zohoCampaignId: '2461504000289074097', beeswaxAdvertiserId: 667 }, // Hamilton Information Systems, Inc.
  { zohoCampaignId: '2461504000289074195', beeswaxAdvertiserId: 667 }, // Hamilton Information Systems, Inc.
  { zohoCampaignId: '2461504000293445284', beeswaxAdvertiserId: 677 }, // Saenger Theatre
  { zohoCampaignId: '2461504000282795080', beeswaxAdvertiserId: 713 }, // Southwest Human Development
  { zohoCampaignId: '2461504000283636471', beeswaxAdvertiserId: 715 }, // Nightingale College
  { zohoCampaignId: '2461504000289148608', beeswaxAdvertiserId: 719 }, // Vantage Senior Living
  { zohoCampaignId: '2461504000289148946', beeswaxAdvertiserId: 719 }, // Vantage Senior Living
  { zohoCampaignId: '2461504000289300296', beeswaxAdvertiserId: 719 }, // Vantage Senior Living
  { zohoCampaignId: '2461504000289300650', beeswaxAdvertiserId: 719 }, // Vantage Senior Living
  { zohoCampaignId: '2461504000289300980', beeswaxAdvertiserId: 719 }, // Vantage Senior Living
  { zohoCampaignId: '2461504000289309396', beeswaxAdvertiserId: 719 }, // Vantage Senior Living
  { zohoCampaignId: '2461504000289309738', beeswaxAdvertiserId: 719 }, // Vantage Senior Living
  { zohoCampaignId: '2461504000289318085', beeswaxAdvertiserId: 719 }, // Vantage Senior Living
  { zohoCampaignId: '2461504000289318424', beeswaxAdvertiserId: 719 }, // Vantage Senior Living
  { zohoCampaignId: '2461504000289335171', beeswaxAdvertiserId: 719 }, // Vantage Senior Living
  { zohoCampaignId: '2461504000269909010', beeswaxAdvertiserId: 730 }, // Aqueduct Plumbing
  { zohoCampaignId: '2461504000285474231', beeswaxAdvertiserId: 730 }, // Aqueduct Plumbing
  { zohoCampaignId: '2461504000280743092', beeswaxAdvertiserId: 731 }, // The Fussell Group Insurance Advisors
  { zohoCampaignId: '2461504000289362375', beeswaxAdvertiserId: 734 }, // KAST Construction
  { zohoCampaignId: '2461504000286687050', beeswaxAdvertiserId: 738 } // Lighthouse Exteriors
];

export function getWhitelistedZohoCampaign(zohoCampaignId: string) {
  return whitelistedZohoCampaigns.find(campaign => campaign.zohoCampaignId == zohoCampaignId);
}
