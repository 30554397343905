import { IAfterGuiAttachedParams } from '@ag-grid-community/core';
import { CustomFilterProps, useGridFilter } from '@ag-grid-community/react';
import React, { useCallback, useRef } from 'react';
import { trpc } from '../../utils/trpc-client';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function AccountManagerFilter({ onModelChange }: CustomFilterProps) {
  const { data, isLoading } = trpc.accountManagers.useQuery();
  return (
    <SelectFilter
      label="Lead Account Manager"
      data={data}
      isLoading={isLoading}
      onModelChange={onModelChange}
    />
  );
}

export function ClientSuccessDirectorFilter({ onModelChange }: CustomFilterProps) {
  const { data, isLoading } = trpc.clientSuccessDirectors.useQuery();
  return (
    <SelectFilter
      label="Client Success Director"
      data={data}
      isLoading={isLoading}
      onModelChange={onModelChange}
    />
  );
}

type SelectFilterProps = {
  label: string;
  data: { full_name: string }[] | undefined;
  isLoading: boolean;
  onModelChange: CustomFilterProps['onModelChange'];
};

function SelectFilter({ label, data, isLoading, onModelChange }: SelectFilterProps) {
  const refInput = useRef<HTMLSelectElement>(null);
  const doesFilterPass = useCallback(() => true, []);

  const afterGuiAttached = useCallback((params?: IAfterGuiAttachedParams) => {
    if (!params || !params.suppressFocus) {
      // Focus the input element for keyboard navigation.
      // Can't do this in an effect,
      // as the component is not recreated when hidden and then shown again
      refInput.current?.focus();
    }
  }, []);

  // register filter handlers with the grid
  useGridFilter({ doesFilterPass, afterGuiAttached });

  function selectUser(value: string) {
    if (value === '' || value === 'all') {
      onModelChange(null);
    } else {
      onModelChange({ value, filterType: 'equals' });
    }
  }

  return (
    <div className="w-64 p-4">
      <div className="flex items-center">
        <div>{label}</div>
        {isLoading && (
          <FontAwesomeIcon icon={faSpinner} className="animate-rotate ml-2 text-blue-500" />
        )}
      </div>
      <div className="mt-2">
        <select
          ref={refInput}
          className="w-full rounded border-2 border-gray-300"
          disabled={isLoading}
          onChange={event => selectUser(event.target.value)}>
          <option value="all">All</option>
          {data &&
            data.map(({ full_name }, idx) => (
              <option key={idx} value={full_name}>
                {full_name}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}
