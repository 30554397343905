import React from 'react';
import { CombinedMediaBuy } from '../../store/strategy-combiner';
import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { dateFormatter, defaultColDef, formatCurrency } from '../../components/table-utils';
import { useLocation, useNavigate } from 'react-router-dom';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { MediaBuyUpdate } from 'shared/src/media-buy-types';

function getColumnDefs(): (
  | ColDef<CombinedMediaBuy & { button: '' }>
  | ColGroupDef<CombinedMediaBuy>
)[] {
  return [
    { field: 'button', cellRenderer: ViewButtonComponent, flex: 1, headerName: '-' },
    {
      field: 'media_platform_id',
      headerName: 'Platform',
      valueFormatter: params => params.data?.media_platform_entity?.media_platform.name || '',
      cellStyle: ({ data }) => cellStyle(data, 'media_platform_id')
    },
    { field: 'name', cellStyle: ({ data }) => cellStyle(data, 'name') },
    {
      field: 'budget',
      cellDataType: 'number',
      editable: true,
      valueFormatter: params => formatCurrency(params.data?.budget || 0),
      cellStyle: ({ data }) => cellStyle(data, 'budget'),
      onCellValueChanged: ({ oldValue, newValue, data }) => {}
    },
    {
      cellDataType: 'date',
      editable: true,
      field: 'start_date',
      headerName: 'Start date',
      valueFormatter: params => dateFormatter(params.data?.start_date),
      cellStyle: ({ data }) => cellStyle(data, 'start_date'),
      onCellValueChanged: ({ oldValue, newValue, data }) => {}
    },
    {
      cellDataType: 'date',
      editable: true,
      field: 'end_date',
      headerName: 'End date',
      valueFormatter: params => dateFormatter(params.data?.end_date),
      cellStyle: ({ data }) => cellStyle(data, 'end_date'),
      onCellValueChanged: ({ oldValue, newValue, data }) => {}
    }
  ];
}

type Props = {
  mediaBuys: CombinedMediaBuy[];
};

export function MediaBuysTable({ mediaBuys }: Props) {
  return (
    <div className="overflow-auto">
      <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          rowData={mediaBuys}
          columnDefs={getColumnDefs()}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
        />
      </div>
    </div>
  );
}

function cellStyle(data: CombinedMediaBuy | undefined, key: keyof MediaBuyUpdate) {
  return data?.state === 'new' || data?.dirty[key]
    ? { fontWeight: 'bold' }
    : { fontWeight: 'normal' };
}

function ViewButtonComponent({ data }: { data: CombinedMediaBuy }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className="flex h-full items-center">
      <button
        className="flex h-[32px] items-center justify-center rounded-2xl border-2 border-gray-400 bg-gray-100 px-4 hover:bg-gray-200"
        onClick={() => navigate(`${pathname}/../mediabuys/${data.id}/overview`)}>
        View
      </button>
    </div>
  );
}
