import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { CombinedStrategy } from '../../store/strategy-combiner';
import { Campaign } from 'shared/src/types';

type ContextValue = {
  campaign: Campaign | undefined;
  strategy: CombinedStrategy;
  refetch: () => Promise<unknown>;
};

// TODO[mk] Changes to the line items can cause additional unnecessary renders
//  Consider splitting it up if it becomes an issue
const StrategyContext = createContext<ContextValue | null>(null);

type Props = {
  campaign: Campaign | undefined;
  strategy: CombinedStrategy;
  refetch: () => Promise<unknown>;
  children: ReactNode;
};
export function StrategyContextProvider({ campaign, strategy, refetch, children }: Props) {
  const value = useMemo(() => ({ campaign, strategy, refetch }), [campaign, strategy, refetch]);
  return <StrategyContext.Provider value={value}>{children}</StrategyContext.Provider>;
}

export function useStrategyContext() {
  const value = useContext(StrategyContext);
  if (!value) {
    throw new Error('useStrategyContext must be used within a StrategyContextProvider');
  }
  return value;
}
