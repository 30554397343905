import React from 'react';
import Modal from '../../components/modal';
import { Button } from '../../components/button';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CreateMediaBuyOptions } from 'shared/src/types';
import { useStore } from '../../store/store';
import { BEESWAX_MEDIA_PLATFORM_ID } from 'shared/src/media-platforms';
import { ZohoCampaign } from 'shared/src/zoho-types';
import { format } from 'date-fns';
import { UTCDate } from '@date-fns/utc';
import { generatePlatformLineItemName } from 'shared/src/zoho-utils';
import { PartialLineItem } from 'shared/src/line-item-types';
import { checkMediaBuyCreationAllowed } from '../strategy/allowed-actions-utils';
import { CombinedLineItem } from '../../store/strategy-combiner';
import { DialogTitle } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { calcUnitCost } from 'shared/src/line-item-utils';
import { formatMoney } from 'shared/src/money-utils';

type Props = {
  lineItem: CombinedLineItem;
  campaign: ZohoCampaign | undefined;
};

export function NewMediaBuyButton({ lineItem, campaign }: Props) {
  const [open, setOpen] = React.useState(false);
  const addMediaBuy = useStore(state => state.addMediaBuy);

  const disabled =
    campaign == null || checkMediaBuyCreationAllowed(campaign, lineItem).type === 'alert';

  async function createMediaBuy(options: CreateMediaBuyOptions) {
    setOpen(false);
    addMediaBuy(options);
  }
  // TODO [ac] is this the best approach here?
  if (!campaign) {
    return <>Loading...</>;
  }
  return (
    <>
      <Button disabled={disabled} onClick={() => setOpen(true)}>
        <div className="mr-2">New Media Buy</div>
      </Button>
      <Modal
        header={<CreateMediaBuyHeader onClose={() => setOpen(false)} />}
        open={open}
        setOpen={setOpen}
        content={
          <CreateMediaBuyForm
            createMediaBuy={createMediaBuy}
            lineItem={lineItem}
            campaign={campaign}
          />
        }
        footer={<Footer enabled={true} />}
      />
    </>
  );
}

type FormData = {
  platform: string;
  mediaBuyName: string;
  mediaBuyNameSuffix: string;
  startDate: Date;
  endDate: Date;
  budget: number;
  cpm: number;
};

function CreateMediaBuyHeader({ onClose }: { onClose: () => void }) {
  return (
    <div className="flex h-12 justify-between px-4">
      <div />
      <DialogTitle as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
        Create new Media Buy
      </DialogTitle>
      <FontAwesomeIcon className="hover:cursor-pointer" icon={faClose} onClick={onClose} />
    </div>
  );
}

type FormProps = {
  lineItem: PartialLineItem;
  createMediaBuy: (options: CreateMediaBuyOptions) => unknown;
  campaign: ZohoCampaign;
};

function CreateMediaBuyForm({ lineItem, createMediaBuy, campaign }: FormProps) {
  const { register, handleSubmit, formState } = useForm<FormData>();
  const { errors } = formState;
  const onSubmit: SubmitHandler<FormData> = data => {
    createMediaBuy({
      strategyId: lineItem.strategy_id,
      lineItemId: lineItem.id,
      platformId: BEESWAX_MEDIA_PLATFORM_ID,
      name: generatePlatformLineItemName(campaign, lineItem, data.mediaBuyNameSuffix),
      startDate: data.startDate,
      endDate: data.endDate,
      budget: data.budget,
      // TODO [ac] this is for indication only the actual CPM is set server side using the same calculation
      cpm: calcUnitCost(lineItem)! * 1000
    });
  };

  return (
    <div className="flex min-w-[600px] justify-center">
      <form
        id="create-media-buy"
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-96 flex-col pb-16">
        <label className="mt-4 font-light text-gray-400" htmlFor="platform">
          Platform
        </label>
        <select
          className="rounded border-2 border-gray-300"
          id="platform"
          {...register('platform', { required: true })}>
          <option value="Beeswax">Beeswax</option>
        </select>
        <RequiredField show={errors.platform != null} />

        <label className="font-light text-gray-400" htmlFor="mediaBuyName">
          Name
        </label>
        <input
          className="rounded border-2 border-gray-300"
          id="mediaBuyName"
          type="text"
          disabled={true}
          autoComplete="off"
          placeholder={`${generatePlatformLineItemName(campaign, lineItem)}`}
          {...register('mediaBuyName', { required: false })}
        />
        <RequiredField show={errors.mediaBuyName != null} />

        <label className="font-light text-gray-400" htmlFor="mediaBuyName">
          Name Suffix
        </label>
        <input
          className="rounded border-2 border-gray-300"
          id="mediaBuyNameSuffix"
          type="text"
          disabled={false}
          autoComplete="off"
          placeholder="Name Suffix i.e. Behavioural, Contextual"
          defaultValue={undefined}
          {...register('mediaBuyNameSuffix', { required: true })}
        />
        <RequiredField show={errors.mediaBuyNameSuffix != null} />

        <label className="mt-2 font-light text-gray-400" htmlFor="startDate">
          StartDate
        </label>
        <input
          className="rounded border-2 border-gray-300"
          id="startDate"
          type="date"
          placeholder="Start Date"
          defaultValue={
            campaign?.Flight_Date ? campaign.Flight_Date : format(new UTCDate(), 'yyyy-mm-dd')
          }
          {...register('startDate', { required: true })}
        />
        <RequiredField show={errors.startDate != null} />

        <label className="mt-2 font-light text-gray-400" htmlFor="endDate">
          End Date
        </label>
        <input
          id="endDate"
          className="rounded border-2 border-gray-300"
          type="date"
          placeholder="End Date"
          defaultValue={campaign?.End_Date ? campaign.End_Date : format(new Date(), 'yyyy-mm-dd')}
          {...register('endDate', { required: true })}
        />
        <RequiredField show={errors.endDate != null} />

        <label className="mt-2 font-light text-gray-400" htmlFor="budget">
          Budget
        </label>
        <input
          id="budget"
          className="rounded border-2 border-gray-300"
          type="number"
          max={1}
          min={1}
          {...register('budget', { required: true, valueAsNumber: true })}
        />
        <RequiredField show={errors.budget != null} />

        <label className="mt-2 font-light text-gray-400" htmlFor="cpm">
          CPM
        </label>
        <input
          disabled={true}
          id="cpm"
          className="rounded border-2 border-gray-300"
          type="number"
          step={0.01}
          // TODO [ac] this is for indication only the actual CPM is set server side using the same calculation
          placeholder={`${formatMoney(calcUnitCost(lineItem)! * 1000)}`}
          {...register('cpm', { required: false, valueAsNumber: true })}
        />
        <RequiredField show={errors.cpm != null} />
      </form>
    </div>
  );
}

function RequiredField({ show }: { show: boolean }) {
  return show ? (
    <span className="h-6 text-red-500">This field is required</span>
  ) : (
    <span className="h-6" />
  );
}

type FooterProps = {
  enabled: boolean;
};

function Footer({ enabled }: FooterProps) {
  return (
    <div className="flex h-20 items-center justify-end rounded-b-lg border-t-[1px] px-4">
      <button
        disabled={!enabled}
        type="submit"
        form="create-media-buy"
        className="inline-flex items-center rounded-md border border-transparent bg-sky-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 disabled:bg-sky-300">
        Create Media Buy
      </button>
    </div>
  );
}
