import { format, isValid, parse } from 'date-fns';
import { UTCDate } from '@date-fns/utc';

export function formatDateString(dateString: string | null | undefined): string {
  if (!dateString) return '-';
  const date = new UTCDate(dateString);
  if (isValid(date)) return format(date, 'MMM do yyyy');
  console.warn('Invalid date ' + dateString + ' found');
  return '';
}

export function formatDate(
  date: Date | null | undefined,
  formatStr: string = 'MMM do yyyy'
): string {
  return !date ? '-' : format(date, formatStr);
}

export function isValidDate(dateString: string) {
  return isValid(parse(dateString, 'yyyy-MM-dd', new UTCDate()));
}

export function parseDate() {}
