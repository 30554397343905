import { z } from 'zod';

export const UnitPriceType = z.object({
  id: z.number(),
  name: z.union([
    z.literal('CPM'),
    z.literal('CPC'),
    z.literal('CPV'),
    z.literal('CPCV'),
    z.literal('CPA'),
    z.literal('CPL'),
    z.literal('Flat Fee')
  ])
});

export type UnitPriceType = z.infer<typeof UnitPriceType>;
export const unitPriceTypeValues = UnitPriceType.shape.name.options.map(option => option.value);

export const TacticType = z.union([
  z.literal('Programmatic Audio'),
  z.literal('Pandora | AudioGo'),
  z.literal('Spotify'),
  z.literal('CTV/OTT'),
  z.literal('Social CTV/OTT'),
  z.literal('Digital Out of Home'),
  z.literal('Online Video'),
  z.literal('Social Online Video'),
  z.literal('Hulu'),
  z.literal('Display'),
  z.literal('Amazon Display'),
  z.literal('Display CRM'),
  z.literal('Social Display'),
  z.literal('Social In-Banner Video'),
  z.literal('Addressable Geofence or Curation'),
  z.literal('Event Retargeting'),
  z.literal('Geofence'),
  z.literal('B2C Email Marketing'),
  z.literal('B2B Email Marketing'),
  z.literal('Email Marketing Redeployment'),
  z.literal('Email Marketing List Purchase: Matching Postal'),
  z.literal('Email Marketing List Purchase: Mailing Postal'),
  z.literal('Email Marketing List Purchase: Hashed Emails'),
  z.literal('Email Marketing Matchback Reporting'),
  z.literal('Native'),
  z.literal('Native Sponsored Content'),
  z.literal('Meta'),
  z.literal('LinkedIn'),
  z.literal('Pinterest'),
  z.literal('Snapchat'),
  z.literal('TikTok'),
  z.literal('X'),
  z.literal('SEM'),
  z.literal('YouTube Bumper Ads'),
  z.literal('YouTube Trueview')
]);
export type TacticType = z.infer<typeof TacticType>;
export const tacticTypeValues = TacticType.options.map(option => option.value);

export const Platform = z.object({
  id: z.number(),
  name: z.string()
});
export type Platform = z.infer<typeof Platform>;

export const Rate = z.object({
  unitPriceType: UnitPriceType,
  targetMargin: z.number(),
  unitPrice: z.number()
});
export type Rate = z.infer<typeof Rate>;

export const Tactic = z.object({
  id: z.number(),
  name: TacticType,
  platforms: z.array(Platform),
  rates: z.array(Rate)
});
export type Tactic = z.infer<typeof Tactic>;

export const ChannelType = z.union([
  z.literal('Audio'),
  z.literal('Video'),
  z.literal('Display'),
  z.literal('Email Marketing'),
  z.literal('Native'),
  z.literal('Social'),
  z.literal('SEM & YouTube')
]);
export type ChannelType = z.infer<typeof ChannelType>;

export const Channel = z.object({
  id: z.number(),
  name: ChannelType,
  tactics: z.array(Tactic)
});
export type Channel = z.infer<typeof Channel>;

export const ChannelSelection = Channel.omit({ tactics: true });
export type ChannelSelection = z.infer<typeof ChannelSelection>;

export const TacticSelection = Tactic.omit({ platforms: true, rates: true });
export type TacticSelection = z.infer<typeof TacticSelection>;

export function tacticsForChannel(channels: Channel[], channel: ChannelSelection) {
  return channels.find(c => c.id === channel.id)?.tactics.map(t => t) || [];
}

export function unitPriceTypesForChannel(
  channels: Channel[],
  channel: ChannelSelection,
  tactic: TacticSelection
) {
  return (
    channels
      .find(c => c.id === channel.id)
      ?.tactics.find(t => t.id === tactic.id)
      ?.rates.map(rate => rate.unitPriceType) || []
  );
}

export function platformsForTactics(
  channels: Channel[],
  channel: ChannelSelection,
  tactic: TacticSelection
): Platform[] {
  return (
    channels.find(c => c.id === channel.id)?.tactics.find(t => t.id === tactic.id)?.platforms || []
  );
}

export function rateForTacticUnitPriceType(
  channels: Channel[],
  tactic: TacticSelection | undefined,
  unitPriceType: UnitPriceType
) {
  const rate = channels
    .flatMap(c => c.tactics)
    .find(t => t.id === tactic?.id)
    ?.rates.find(r => r.unitPriceType.name === unitPriceType.name);
  if (!rate) {
    throw new Error('Rate not found');
  }
  return rate;
}
