import React, { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import {
  faArrowLeftFromBracket,
  faBars,
  faCog,
  faHouse,
  IconDefinition
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

export function Sidebar() {
  const [expanded, setExpanded] = React.useState(false);
  const [transitionDone, setTransitionDone] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleTransitionEnd = () => setTransitionDone(true);

    const element = containerRef.current;
    if (element) element.addEventListener('transitionend', handleTransitionEnd);

    return () => {
      if (element) element.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, []);

  function logout() {
    window.location.href = '/auth/logout';
  }

  function toggleExpanded() {
    setExpanded(!expanded);
    setTransitionDone(false);
  }

  return (
    <div
      ref={containerRef}
      className={clsx(
        'transform border-r-[1px] border-gray-200 bg-white shadow-xl transition-all duration-300 ease-in-out',
        expanded ? 'w-[250px]' : 'w-[90px]'
      )}>
      <div className="flex h-full flex-col items-center pt-8">
        <MenuItem icon={faBars} onClick={toggleExpanded} />
        <div className="h-16" />
        <MenuItem
          icon={faHouse}
          label="Campaigns"
          showLabel={transitionDone && expanded}
          onClick={() => navigate('/campaigns')}
        />
        <div className="flex-1" />
        <div className="flex h-[200px] w-full flex-col border-t-[1px] border-gray-100 pt-8">
          <MenuItem icon={faCog} label="Settings" showLabel={transitionDone && expanded} />
          <MenuItem
            onClick={logout}
            icon={faArrowLeftFromBracket}
            label="Logout"
            showLabel={transitionDone && expanded}
          />
        </div>
      </div>
    </div>
  );
}

type MenuItemProps = {
  icon: IconDefinition;
  label?: string;
  showLabel?: boolean;
  onClick?: () => void;
};

function MenuItem({ icon, label, showLabel, onClick }: MenuItemProps) {
  return (
    <div className="flex h-[48px] w-full cursor-pointer items-center" onClick={onClick}>
      <div className="flex w-[89px] justify-center">
        <FontAwesomeIcon className="cursor-pointer text-xl" icon={icon} />
      </div>
      {label && showLabel && <MenuText label={label} />}
    </div>
  );
}

export function MenuText({ label, classes }: { label: string; classes?: string }) {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  // Trigger the opacity transition when the component mounts
  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), 20);
    return () => clearTimeout(timeout); // Cleanup timeout if component unmounts
  }, []);

  return (
    <div
      className={clsx(
        'transition-opacity duration-100',
        classes,
        isVisible ? 'opacity-100' : 'opacity-0'
      )}>
      {label}
    </div>
  );
}
