import React, { useMemo, useState } from 'react';
import { MediaBuyGraphData, PerformanceChart } from '../../components/performance-chart';
import { MediaBuyMetric } from 'shared/src/metrics-types';
import { DisplayMode, DisplayModeSelector } from '../../components/charts/display-mode-selector';
import { CombinedLineItem, CombinedMediaBuy } from '../../store/strategy-combiner';
import { convertMetrics } from './media-buy-graph-utils';

const chartTypes = ['spend', 'delivery'] as const; // TODO[mk] add 'margin' once we have values for it
type ChartType = (typeof chartTypes)[number];

type Props = {
  data: MediaBuyMetric[];
  lineItem: CombinedLineItem;
  mediaBuys: CombinedMediaBuy[];
};

export function MediaBuyGraph({ data, lineItem, mediaBuys }: Props) {
  const [displayMode, setDisplayMode] = useState<DisplayMode>('discrete');
  const [chartType, setChartType] = useState<ChartType>('spend');

  const graphData = useMemo<MediaBuyGraphData[]>(() => {
    return convertMetrics(data, lineItem, mediaBuys, displayMode, chartType);
  }, [data, lineItem, mediaBuys, displayMode, chartType]);

  if (!data) return null;

  return (
    <div className="flex w-full min-w-0 flex-1 flex-col">
      <div className="flex items-center justify-between px-8 py-2">
        <ChartTypeSelector selected={chartType} setSelected={setChartType} />
        <DisplayModeSelector displayMode={displayMode} setDisplayMode={setDisplayMode} />
      </div>
      <PerformanceChart graphData={graphData} chartType={chartType} displayMode={displayMode} />
    </div>
  );
}

type ChartTypeSelectorProps = {
  selected: ChartType;
  setSelected: (type: ChartType) => void;
};

function ChartTypeSelector({ selected, setSelected }: ChartTypeSelectorProps) {
  return (
    <div className="ml-4 mr-0 items-center">
      <label htmlFor="options" className="mr-2 text-xs text-gray-700">
        Viewing:
      </label>
      <select
        id="options"
        className="rounded border-0 border-gray-300 text-xs"
        onChange={event => setSelected(chartTypes.find(item => item === event.target.value)!)}
        value={selected}>
        {chartTypes.map(item => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
}
