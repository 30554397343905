import * as React from 'react';

export function Login() {
  return (
    <div className="h-screen bg-gray-100">
      <div className="flex min-h-full flex-1 flex-col py-32 sm:px-6 lg:px-8">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <h1 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Media Tool
              </h1>
            </div>
            <div>
              <div className="relative mt-10">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">Login via</span>
                </div>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-4">
                <a
                  href="/auth/login"
                  className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" className="h-4">
                    <path fill="#f35325" d="M1 1h10v10H1z" />
                    <path fill="#81bc06" d="M12 1h10v10H12z" />
                    <path fill="#05a6f0" d="M1 12h10v10H1z" />
                    <path fill="#ffba08" d="M12 12h10v10H12z" />
                  </svg>
                  <span className="text-sm font-semibold leading-6">Microsoft Entra ID</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
