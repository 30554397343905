import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

type Props = {
  title: string;
  isPending: boolean;
  refresh: () => unknown;
};

export function SyncButton({ title, isPending, refresh }: Props) {
  return (
    <button className="text-xs font-light text-blue-600" onClick={refresh} disabled={isPending}>
      {isPending ? (
        <motion.div
          className={'mr-2'}
          style={{ display: 'inline-block' }}
          animate={{ rotate: 360 }}
          transition={{
            ease: 'linear',
            duration: 2,
            repeat: Infinity
          }}>
          <FontAwesomeIcon icon={faRefresh} />
        </motion.div>
      ) : (
        <FontAwesomeIcon className="mr-2" icon={faRefresh} />
      )}
      {title}
    </button>
  );
}
