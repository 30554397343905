// useAuth.ts
import { useState, useEffect } from 'react';
import { VerifiedToken } from 'shared/src/types';

const AUTH_CACHE_KEY = 'authStatus';

export function useAuth() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [identity, setIdentity] = useState<{ email: string; name: string } | undefined>(undefined);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const cachedAuth = getUserDetails();
        if (cachedAuth) {
          setIsLoggedIn(true);
        }
        const response = await fetch('/auth/status');
        const verified = VerifiedToken.parse(await response.json());
        if (verified) {
          setIsLoggedIn(true);
          setIdentity(verified);
          localStorage.setItem(AUTH_CACHE_KEY, JSON.stringify(verified));
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        setIsLoggedIn(false);
        localStorage.removeItem(AUTH_CACHE_KEY);
      } finally {
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, []);

  return { isLoggedIn, loading, identity };
}

export function useUserDetails() {
  const [userDetails, setUserDetails] = useState<VerifiedToken | undefined>(getUserDetails());

  useEffect(() => {
    const cachedUser = getUserDetails();
    if (cachedUser) {
      setUserDetails(cachedUser);
    }
  }, []);

  return { userDetails };
}

function getUserDetails() {
  const sessionJson = localStorage.getItem(AUTH_CACHE_KEY);
  if (!sessionJson) return undefined;

  const result = VerifiedToken.safeParse(JSON.parse(sessionJson));
  if (result.success) {
    return result.data;
  } else {
    console.error('Invalid Session Token');
    localStorage.removeItem(AUTH_CACHE_KEY);
    return undefined;
  }
}
