import React from 'react';
import { CombinedLineItem, CombinedMediaBuy } from '../../store/strategy-combiner';
import { faCode } from '@fortawesome/pro-light-svg-icons';
import ReactJsonView from '@microlink/react-json-view';
import { ExpandableSidebarSection, PageContentSection, PageSection } from '../../components/layout';
import {
  BEESWAX_MEDIA_PLATFORM_ID,
  TTD_MEDIA_PLATFORM_ID,
  VIANT_MEDIA_PLATFORM_ID
} from 'shared/src/media-platforms';
import { MediaBuyGraph } from './media-buy-graph';
import { useMediaBuyMetrics } from './use-media-buy-metrics';
import { HeroMetrics } from '../../components/hero-metrics';
import { generateMediaBuyMetrics } from './media-buy-overview-utils';

type Props = {
  lineItem: CombinedLineItem;
  mediaBuy: CombinedMediaBuy;
};

export function MediaBuyOverview({ lineItem, mediaBuy }: Props) {
  const { data } = useMediaBuyMetrics(mediaBuy.id);

  return (
    <PageSection>
      <PageContentSection>
        <HeroMetrics heroMetrics={generateMediaBuyMetrics(data, lineItem, mediaBuy)} />
        <MediaBuyGraph data={data} lineItem={lineItem} mediaBuys={[mediaBuy]} />
      </PageContentSection>
      <ExpandableSidebarSection
        sections={[
          {
            name: 'config',
            header: 'Config',
            icon: faCode,
            render: () => <RawConfigSection mediaBuy={mediaBuy} />
          }
        ]}
      />
    </PageSection>
  );
}

function RawConfigSection({ mediaBuy }: { mediaBuy: CombinedMediaBuy | undefined }) {
  return (
    <div className="flex h-full flex-col">
      <ReactJsonView src={mediaBuy?.media_platform_entity?.raw_entity || {}} />
    </div>
  );
}

export function getPlatformEntityStatus(mediaBuy: CombinedMediaBuy | undefined) {
  switch (mediaBuy?.media_platform_id) {
    case BEESWAX_MEDIA_PLATFORM_ID:
      if (mediaBuy?.media_platform_entity?.raw_entity?.['active'] == null) return 'Unknown';
      return mediaBuy?.media_platform_entity?.raw_entity['active'] ? 'Active' : 'Inactive';
    case VIANT_MEDIA_PLATFORM_ID:
      // TODO [ac] status is not in the entity. The platform campaign has a flights array which have status so perhaps
      //  that could be mapped
      return 'Unknown';
    case TTD_MEDIA_PLATFORM_ID:
      if (mediaBuy?.media_platform_entity?.raw_entity?.['IsEnabled'] == null) return 'Unknown';
      return mediaBuy?.media_platform_entity?.raw_entity['IsEnabled'] ? 'Active' : 'Inactive';
    default:
      return `Unknown`;
  }
}
