import { Campaign } from 'shared/src/types';
import React from 'react';
import { formatCurrencyNoDecimal } from '../../components/table-utils';
import { MetricItem } from '../../components/metric-item';

type Props = {
  campaign: Campaign | undefined;
};
export function StrategyMetrics({ campaign }: Props) {
  const price = campaign?.Budget ? formatCurrencyNoDecimal(campaign?.Budget) : undefined;

  return (
    <div className="mb-8 flex items-center justify-between">
      <MetricItem name="Campaign Stage" value={campaign?.Stage} />
      <MetricItem name="Price" value={price} />
      <MetricItem name="Margin" />
      <MetricItem name="Revenue" />
      <MetricItem name="Spend Pacing" />
      <MetricItem name="Delivery Pacing" noBorder />
    </div>
  );
}
