import React from 'react';

type ButtonProps = {
  onClick: () => unknown;
  children: React.ReactNode;
  disabled?: boolean;
};

export function Button({ onClick, children, disabled = false }: ButtonProps) {
  return (
    <button
      disabled={disabled}
      type="button"
      className="inline-flex items-center rounded-md border border-transparent bg-sky-400 px-4 py-2 text-sm font-medium text-white shadow-md hover:bg-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 disabled:bg-sky-300"
      onClick={onClick}>
      {children}
    </button>
  );
}

export function SecondaryButton({ onClick, children, disabled = false }: ButtonProps) {
  return (
    <button
      disabled={disabled}
      type="button"
      className="inline-flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-gray-600 shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 disabled:text-gray-200"
      onClick={onClick}>
      {children}
    </button>
  );
}
