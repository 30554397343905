import { z } from 'zod';
import { LogicalNumberFilter, NumberFilter, ZohoCampaignStatus } from 'shared/src/zoho-types';

export const CampaignsTableFilter = z.object({
  Stage: z
    .object({
      values: z.array(ZohoCampaignStatus),
      filterType: z.literal('set')
    })
    .optional(),
  'Lead_Account_Owner.name': z
    .object({
      value: z.string(),
      filterType: z.literal('equals')
    })
    .optional(),
  'Sales_Rep.name': z
    .object({
      value: z.string(),
      filterType: z.literal('equals')
    })
    .optional(),
  Price: z.union([NumberFilter, LogicalNumberFilter]).optional()
});
export type CampaignsTableFilter = z.infer<typeof CampaignsTableFilter>;
