import React from 'react';
import { formatMoney } from 'shared/src/money-utils';
import { CombinedLineItem } from '../../store/strategy-combiner';
import { format, max, min } from 'date-fns';
import { Button } from '../../components/button';
import { createNextBlock, getCumulativeSpend, isOverlapping } from './pacing-helpers';
import { useStore } from '../../store/store';
import { PacingChart } from './pacing-chart';
import { formatDate, isValidDate } from 'shared/src/date-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { DialogTitle } from '@headlessui/react';
import { PacingBlock } from 'shared/src/line-item-types';
import { UTCDate } from '@date-fns/utc';
import { CloseButton, Hr } from '../pending-changes/pending-changes';

type Props = {
  lineItem: CombinedLineItem;
};

export function LineItemPacing({ lineItem }: Props) {
  const [updateLineItem, setLineItemPacing, lineItemPacing] = useStore(state => [
    state.updateLineItem,
    state.setLineItemPacing,
    state.lineItemPacing
  ]);

  const originalLineItem = lineItem.state.type === 'update' ? lineItem.state.original : undefined;

  function close() {
    setLineItemPacing(lineItemPacing ? { ...lineItemPacing, open: false } : null);
  }

  function addNewBlock() {
    const block = createNextBlock(lineItem);
    if (block) {
      const pacing_details = {
        blocks: [...(lineItem.pacing_details?.blocks || []), block]
      };
      updateLineItem(
        { id: lineItem.id, strategy_id: lineItem.strategy_id, pacing_details },
        originalLineItem
      );
    }
  }

  function updateBlock(idx: number, update: Partial<PacingBlock>) {
    const { pacing_details } = lineItem;
    if (pacing_details && pacing_details.blocks[idx]) {
      updateLineItem(
        {
          id: lineItem.id,
          strategy_id: lineItem.strategy_id,
          pacing_details: { blocks: updatePacingDetails(pacing_details.blocks, idx, update) }
        },
        originalLineItem
      );
    }
  }

  function deleteBlock(idx: number) {
    const { pacing_details } = lineItem;
    if (pacing_details && pacing_details.blocks[idx]) {
      updateLineItem(
        {
          id: lineItem.id,
          strategy_id: lineItem.strategy_id,
          pacing_details: { blocks: pacing_details.blocks.filter((_, i) => i !== idx) }
        },
        originalLineItem
      );
    }
  }

  // TODO[mk] - show a message to the user if the start date and end date are not set
  if (!lineItem.pacing_details || !lineItem.start_date || !lineItem.end_date || !lineItem.price)
    return null;

  const { blocks } = lineItem.pacing_details;

  const data = getCumulativeSpend(lineItem.start_date, lineItem.end_date, blocks);
  const totalBudget = blocks.reduce((acc, segment) => acc + segment.price, 0);

  return (
    <div className="flex h-full w-[750px] flex-col bg-white shadow-xl">
      <div className="px-6 py-6">
        <div className="flex items-start justify-between">
          <DialogTitle className="text-lg font-medium text-gray-900">
            {lineItem.name} - Pacing Schedule
          </DialogTitle>
          <CloseButton onClick={close} />
        </div>
        <div className="mt-4 flex justify-between">
          <div className="flex">
            <div className="mr-2 font-light text-gray-400">Start Date:</div>
            <div>{formatDate(lineItem.start_date)}</div>
          </div>
          <div className="flex">
            <div className="mr-2 font-light text-gray-400">End Date:</div>
            <div>{formatDate(lineItem.end_date)}</div>
          </div>
          <div className="flex">
            <div className="mr-2 font-light text-gray-400">Budget:</div>
            <div>{formatMoney(lineItem.price)}</div>
          </div>
        </div>
      </div>
      <div className="mt-2 flex-1 overflow-y-auto">
        <PacingChart data={data} />
        <div className="px-8 pb-8 pt-2">
          <div className="mb-2">
            <div className="grid grid-cols-[250px_250px_1fr_auto] gap-2 font-light">
              <div className="text-sm font-bold">Start Date</div>
              <div className="text-sm font-bold">End Date</div>
              <div className="text-sm font-bold">Spend</div>
              <div className="text-sm font-bold" />
              {blocks.map((block, idx) => (
                <BlockRow
                  isOverlapping={isOverlapping(blocks, idx)}
                  key={idx}
                  lineItem={lineItem}
                  block={block}
                  idx={idx}
                  updateBlock={updateBlock}
                  deleteBlock={deleteBlock}
                />
              ))}
            </div>
          </div>
          <NewBlockButton addNewBlock={addNewBlock} />
        </div>
      </div>
      <div className="px-6 py-6 ">
        <div className="mb-4 mt-4 flex justify-between">
          <div className="mr-2 font-light">Allocated: {formatMoney(totalBudget)}</div>
          <div
            className={`mr-2 font-light ${lineItem.price !== totalBudget ? 'text-red-500' : ''}`}>
            Remaining: {formatMoney((lineItem.price || 0) - totalBudget)}
          </div>
        </div>
        <Hr />
        <div className="mt-6 w-full ">
          <button
            className="inline-flex h-12 w-full items-center justify-center rounded-md border border-transparent bg-sky-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 disabled:bg-sky-300"
            onClick={close}>
            <div className="mr-2">Done</div>
          </button>
        </div>
      </div>
    </div>
  );
}

type BlockRowProps = {
  lineItem: CombinedLineItem;
  block: PacingBlock;
  updateBlock: (idx: number, update: Partial<PacingBlock>) => void;
  deleteBlock: (idx: number) => void;
  idx: number;
  isOverlapping: { start_date: boolean; end_date: boolean };
};

function BlockRow({
  lineItem,
  block,
  updateBlock,
  deleteBlock,
  idx,
  isOverlapping
}: BlockRowProps) {
  function updateStartDate(date: string, idx: number) {
    if (isValidDate(date)) updateBlock(idx, { start_date: new UTCDate(date) });
  }

  function updateEndDate(date: string, idx: number) {
    if (isValidDate(date)) updateBlock(idx, { end_date: new UTCDate(date) });
  }

  function updatePrice(price: number, idx: number) {
    updateBlock(idx, { price });
  }

  const { start_date, end_date } = lineItem;
  if (!start_date || !end_date) return null;

  return (
    <>
      <div>
        <input
          className={`block w-full rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${isOverlapping.start_date ? 'ring-red-500 focus:ring-red-500' : ''}`}
          id="startDate"
          type="date"
          min={format(start_date, 'yyyy-MM-dd')}
          max={format(min([block.end_date, end_date]), 'yyyy-MM-dd')}
          value={format(block.start_date, 'yyyy-MM-dd')}
          onChange={event => updateStartDate(event.target.value, idx)}
          placeholder="Start Date"
        />
      </div>
      <div>
        <input
          className={`block w-full rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${isOverlapping.end_date ? 'ring-red-500 focus:ring-red-500' : ''}`}
          id="startDate"
          type="date"
          min={format(max([start_date, block.start_date]), 'yyyy-MM-dd')}
          max={format(end_date, 'yyyy-MM-dd')}
          value={format(block.end_date, 'yyyy-MM-dd')}
          onChange={event => updateEndDate(event.target.value, idx)}
          placeholder="End Date"
        />
      </div>
      <div>
        <div className="relative rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
          <input
            className="block w-full rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            id="price"
            type="number"
            defaultValue={block.price}
            onBlur={event => updatePrice(Number(event.target.value), idx)}
            onKeyUp={event => {
              if (event.key === 'Enter') {
                updatePrice(Number(event.currentTarget.value), idx);
              }
            }}
          />
        </div>
      </div>
      <div className="flex w-8 items-center justify-center">
        <FontAwesomeIcon
          onClick={() => deleteBlock(idx)}
          className="text-gray-400 hover:cursor-pointer hover:text-red-500"
          icon={faTrashCan}
        />
      </div>
    </>
  );
}

type NewBlockButtonProps = {
  addNewBlock: () => void;
};

function NewBlockButton({ addNewBlock }: NewBlockButtonProps) {
  return (
    <div>
      <Button onClick={addNewBlock}>
        <div className="w-full">Create Budget Block</div>
      </Button>
    </div>
  );
}

function updatePacingDetails(pacing: PacingBlock[], idx: number, update: Partial<PacingBlock>) {
  if (idx < 0 || idx >= pacing.length) {
    throw new Error('Index out of bounds for pacing');
  }

  return pacing.map((item, i) => (i === idx ? { ...item, ...update } : item));
}
