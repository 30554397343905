import { PartialLineItem } from 'shared/src/line-item-types';

export function calcEstimatedUnits(lineItem: PartialLineItem) {
  const { price, unit_price } = lineItem;
  return price && unit_price ? Math.round(price / unit_price) : undefined;
}

export function calcAdjustedUnitCost(lineItem: PartialLineItem) {
  const { unit_price, target_margin } = lineItem;
  if (unit_price == null || target_margin == null) return undefined;
  return lineItem.unit_price_type?.name === 'CPM'
    ? unit_price * 1000 * (1 - target_margin)
    : unit_price * (1 - target_margin);
}

export function calcUnitCost(lineItem: PartialLineItem) {
  const { unit_price, target_margin } = lineItem;
  if (unit_price == null || target_margin == null) return undefined;
  return unit_price * (1 - target_margin);
}

export function calcMediaBudget(lineItem: PartialLineItem) {
  const estimatedUnits = calcEstimatedUnits(lineItem);
  const unitCost = calcUnitCost(lineItem);
  return estimatedUnits && unitCost ? estimatedUnits * unitCost : undefined;
}

export function calcPacingBlockBudget(lineItem: PartialLineItem, price: number) {
  const { unit_price, target_margin } = lineItem;
  if (unit_price == null || unit_price === 0 || target_margin == null) return undefined;
  const estimatedUnits = Math.round(price / unit_price);
  const unitCost = unit_price * (1 - target_margin);
  return estimatedUnits * unitCost;
}
