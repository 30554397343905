import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { Channel } from 'shared/src/line-item-channels';

type ContextValue = {
  channels: Channel[];
};

const ChannelRatesContext = createContext<ContextValue | null>(null);

type Props = {
  channels: Channel[];
  children: ReactNode;
};
export function ChannelRatesContextProvider({ channels, children }: Props) {
  const value = useMemo(() => ({ channels }), [channels]);
  return <ChannelRatesContext.Provider value={value}>{children}</ChannelRatesContext.Provider>;
}

export function useChannelRatesContext() {
  const value = useContext(ChannelRatesContext);
  if (!value) {
    throw new Error('useChannelRatesContext must be used within a ChannelRatesContextProvider');
  }
  return value;
}
