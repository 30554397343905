import React from 'react';
import { trpc } from '../../utils/trpc-client';
import { SyncButton } from '../../components/sync-button';

type Props = {
  mediaBuyId: string;
  refetch: () => unknown;
};

export function RefreshMediaBuyButton({ refetch, mediaBuyId }: Props) {
  const { mutateAsync, isPending } = trpc.refreshPlatformLineItem.useMutation();

  async function refresh() {
    await mutateAsync({ id: mediaBuyId });
    refetch();
  }

  return <SyncButton title={'Refresh data'} isPending={isPending} refresh={refresh} />;
}
