import { z } from 'zod';

export const User = z.object({
  id: z.string(),
  zoho_user_id: z.string(),
  name: z.string(),
  email: z.string()
});
export type User = z.infer<typeof User>;

export const Team = z.object({
  leadAccountManager: User,
  clientSuccessDirector: User,
  adOps: z.array(User),
  analytics: User,
  seniorMediaTrader: User,
  mediaTraders: z.array(User)
});

export type Team = z.infer<typeof Team>;
