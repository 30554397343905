import React from 'react';

export function DetailItem({ label, value }: { label: string; value: string | null | undefined }) {
  return (
    <div className="my-2 flex-col">
      <div className="text-sm font-light text-gray-500">{label}</div>
      <div className="mt-1 font-bold">{value || '-'}</div>
    </div>
  );
}
