import React from 'react';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { LineItemTableData } from './line-items-table';
import { Platform } from 'shared/src/line-item-channels';

export function PlatformCellRenderer({
  value
}: CustomCellRendererProps<LineItemTableData, Platform | Platform[]>) {
  if (!value) return null;

  return <div>{Array.isArray(value) ? value.map(v => v.name).join(', ') : value.name}</div>;
}
