import { MediaBuyMetric } from 'shared/src/metrics-types';
import { CombinedLineItem, CombinedMediaBuy } from '../../store/strategy-combiner';
import { groupBy } from 'lodash';
import { DisplayMode } from '../../components/charts/display-mode-selector';
import { UTCDate } from '@date-fns/utc';
import { addDays, differenceInDays } from 'date-fns';

export function convertMetrics(
  data: MediaBuyMetric[],
  lineItem: CombinedLineItem,
  mediaBuys: CombinedMediaBuy[],
  displayMode: DisplayMode,
  chartType: 'spend' | 'delivery'
) {
  const { start_date, end_date } = lineItem;
  if (data.length === 0 || !start_date || !end_date) return [];
  const byMediaBuy = groupMetrics(data);
  return byMediaBuy.map(metrics => {
    const mediaBuy = mediaBuys.find(buy => buy.id === metrics[0].MEDIA_BUY_ID);
    return {
      id: mediaBuy?.id || 'Unknown',
      name: mediaBuy?.name || 'Unknown',
      priceType: lineItem.unit_price_type?.name || 'CPM',
      metrics: generateMetrics(metrics, displayMode, chartType),
      planned: generateAveragePlanned(metrics, displayMode, chartType, start_date, end_date)
    };
  });
}

function groupMetrics(metrics: MediaBuyMetric[]) {
  const result = groupBy(metrics, 'MEDIA_BUY_ID');
  return Object.values(result);
}

function generateMetrics(
  data: MediaBuyMetric[],
  displayMode: DisplayMode,
  chartType: 'spend' | 'delivery'
) {
  const fn = accessorFn(chartType, displayMode);
  return data.map(d => ({ date: d.BUCKET, value: fn(d) }));
}

function accessorFn(chartType: 'spend' | 'delivery', displayMode: DisplayMode) {
  if (chartType === 'spend' && displayMode === 'discrete') {
    return (d: MediaBuyMetric) => d.ACTUAL_MEDIA_SPEND;
  } else if (chartType === 'spend' && displayMode === 'cumulative') {
    return (d: MediaBuyMetric) => d.ACTUAL_MEDIA_SPEND_CUMULATIVE;
  } else if (chartType === 'delivery' && displayMode === 'discrete') {
    return (d: MediaBuyMetric) => d.ACTUAL_DELIVERED_UNITS;
  } else {
    return (d: MediaBuyMetric) => d.ACTUAL_DELIVERED_UNITS_CUMULATIVE;
  }
}

function generateAveragePlanned(
  data: MediaBuyMetric[],
  displayMode: DisplayMode,
  chartType: 'spend' | 'delivery',
  startDate: Date,
  endDate: Date
) {
  const utcStart = new UTCDate(startDate);
  const utcEnd = new UTCDate(endDate);
  const fn = accessorFn(chartType, 'discrete');
  if (data.length === 0) return [];
  const total = data.map(data => fn(data)).reduce((a, b) => a + b, 0);
  const daily = total / data.length;
  // map a day per each day between start and end date
  const numDays = differenceInDays(utcEnd, utcStart);

  const planned = Array.from({ length: numDays }, (_, i) => ({
    date: addDays(utcStart, i),
    value: daily
  }));
  if (displayMode === 'discrete') return planned;

  let sum = 0;
  return planned.map(d => ({ ...d, value: (sum += d.value) }));
}
