import { CombinedLineItem } from '../../store/strategy-combiner';
import { areRequiredFieldsSet } from '../line-items/line-item-errors';
import { ZohoCampaign } from 'shared/src/zoho-types';
import { isProd } from '../../utils/env-utils';
import { getWhitelistedZohoCampaign } from 'shared/src/whitelisted-zoho-campaigns';

type DisabledAlert = {
  type: 'alert';
  level: 'error' | 'warning';
  header: string;
  message: string;
};
type Allowed = {
  type: 'allowed';
};
type CheckResponse = DisabledAlert | Allowed;

export function checkLineItemCreationAllowed(campaign: ZohoCampaign): CheckResponse {
  if (!campaignIsWhitelisted(campaign)) {
    return {
      type: 'alert',
      level: 'warning',
      header: 'Campaign has not been whitelisted for the Media Tool Pilot',
      message:
        'Media Buys cannot be created for this campaign, however you can create Line Items to try the tool out'
    };
  }
  if (campaign.Budget == null || campaign.Budget <= 0) {
    return {
      type: 'alert',
      level: 'warning',
      header: 'New Line Items cannot be created for this Campaign',
      message: 'The Campaign Budget must be set in Zoho in order to create new Line Items.'
    };
  }
  return { type: 'allowed' };
}

export function checkMediaBuyCreationAllowed(
  campaign: ZohoCampaign | undefined,
  lineItem: CombinedLineItem
): CheckResponse {
  if (!campaignIsWhitelisted(campaign)) {
    return {
      type: 'alert',
      level: 'warning',
      header: 'Campaign has not been whitelisted for the Media Tool Pilot',
      message: 'Media Buys cannot be created for this campaign'
    };
  }
  if (!campaignHasBudget(campaign)) {
    return {
      type: 'alert',
      level: 'warning',
      header: 'New Line Items cannot be created for this Campaign',
      message: 'The Campaign Budget must be set in Zoho in order to create new Line Items.'
    };
  }
  if (!isLineItemReady(lineItem)) {
    return {
      type: 'alert',
      level: 'error',
      header: 'Missing required fields',
      message:
        'Media buy creation is not allowed until all required Line Item fields are set and there are no pending Line Item changes'
    };
  }

  return { type: 'allowed' };
}

export function campaignHasBudget(campaign: ZohoCampaign | undefined): boolean {
  return campaign != null && campaign.Budget != null && campaign.Budget > 0;
}

function campaignIsWhitelisted(campaign: ZohoCampaign | undefined): boolean {
  // If we are not in prod, allow the creation of Media Buys
  if (!isProd) return true;
  else return campaign != null && getWhitelistedZohoCampaign(campaign.id) != null;
}

function isLineItemReady(lineItem: CombinedLineItem): boolean {
  return lineItem.state.type === 'unchanged' && areRequiredFieldsSet(lineItem);
}
