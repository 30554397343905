import React, { useEffect, useRef } from 'react';
import {
  CustomCellEditorProps,
  CustomCellRendererProps,
  CustomTooltipProps
} from '@ag-grid-community/react';
import { LineItemTableData } from './line-items-table';
import { RateCardIcon } from './rate-card-icon';
import { formatCurrency } from '../../components/table-utils';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/pro-light-svg-icons';
import { Channel, rateForTacticUnitPriceType } from 'shared/src/line-item-channels';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

export type CellRendererProps<T> = CustomCellRendererProps<LineItemTableData, T>;
export type CellEditorProps<T> = CustomCellEditorProps<LineItemTableData, T>;
type RendererWithChannelsProps = { channels: Channel[] } & CellRendererProps<number>;

export function ChannelCellRenderer({ value }: CellRendererProps<string>) {
  return value ? getValueString(value) : <div className="text-blue-500">Select the Channel</div>;
}

export function TargetMarginCellEditor({ value, onValueChange }: CellEditorProps<number>) {
  const inputRef = useRef<HTMLInputElement>(null);

  // Ensure the input is focused when the editor is opened
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  function handleChange(value: string) {
    const num = parseFloat(value);
    if (!isNaN(num) && num >= 0) {
      const value1 = Number((num / 100).toFixed(4));
      onValueChange(value1);
    }
  }

  return (
    <div className="flex h-full w-full rounded-[2px] border-[1px] border-[#2096f3] bg-white">
      <input
        className="w-full rounded-none border-none border-transparent pl-4 text-sm outline-none focus:border-transparent"
        ref={inputRef}
        min={0}
        max={100}
        step={1}
        type="number"
        value={value ? Number((value * 100).toFixed(2)) : 0}
        onChange={({ target }) => handleChange(target.value)}
      />
      <div className="bg-white px-1">%</div>
    </div>
  );
}

export function TargetMarginCellRenderer(props: RendererWithChannelsProps) {
  const { data, value, channels } = props;
  return (
    <RateCardValue
      disabled={props.data?.unit_price_type == null}
      value={targetMarginTextValue(props.value)}
      diff={determineTargetMarginDiff({ data, value, channels })}
    />
  );
}

export function TargetMarginTooltip({
  value,
  data,
  channels
}: CustomTooltipProps & { channels: Channel[] }) {
  const diff = determineTargetMarginDiff({ data, value, channels });
  if (!diff || !value || !data) return null;
  const rate = rateForTacticUnitPriceType(channels, data.tactic, data.unit_price_type);
  return (
    <div className="rounded-lg border-[1px] border-gray-300 bg-white p-4 shadow-lg">
      Target Margin is {Math.abs(diff).toFixed(1)}% {diff > 0 ? 'above' : 'below'} the rate card
      value of {(rate.targetMargin * 100).toFixed(2)}%
    </div>
  );
}

function targetMarginTextValue(value: number | null | undefined) {
  if (value == null) return 'Filled after Channel';
  return value ? `${(value * 100).toFixed(2)}%` : '';
}

type RateCardDiffParams = {
  data: LineItemTableData | undefined;
  value: number | null | undefined;
  channels: Channel[];
};

function determineTargetMarginDiff({ data, value, channels }: RateCardDiffParams) {
  if (data == null || !data.unit_price_type || !data.tactic || value == null) return undefined;
  const rate = rateForTacticUnitPriceType(channels, data.tactic, data.unit_price_type);
  if (rate.targetMargin === 0 || value === rate.targetMargin) return undefined;
  return ((value - rate.targetMargin) / rate.targetMargin) * 100;
}

export function UnitPriceEditor({ data, value, onValueChange }: CellEditorProps<number>) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  function handleChange(value: string) {
    const num = parseFloat(value);
    if (isNaN(num)) {
      onValueChange(undefined);
    } else {
      onValueChange(data.unit_price_type?.name === 'CPM' ? num / 1000 : num);
    }
  }

  return (
    <div className="relative flex h-full w-full items-center rounded-[2px] border-[1px] border-[#2096f3] bg-white">
      <div className="absolute left-3">$</div>
      <input
        className="w-full rounded-none border-0 border-none pl-6 text-sm outline-none focus:ring-0"
        ref={inputRef}
        type="number"
        value={unitPriceValue(data, value) || ''}
        onChange={({ target }) => handleChange(target.value)}
      />
    </div>
  );
}

function unitPriceValue(data: LineItemTableData, value: number | null | undefined) {
  if (value == null) return null;
  return data.unit_price_type?.name === 'CPM' ? value * 1000 : value;
}

export function UnitPriceCellRenderer(props: RendererWithChannelsProps) {
  return (
    <RateCardValue
      disabled={props.data?.unit_price_type == null}
      value={unitPriceTextValue(props.data, props.value)}
      diff={determineUnitPriceDiff(props)}
    />
  );
}

export function UnitPriceTooltip({
  value,
  data,
  channels
}: CustomTooltipProps & { channels: Channel[] }) {
  const diff = determineUnitPriceDiff({ data, value, channels });
  if (!diff || !value || !data) return null;
  const rate = rateForTacticUnitPriceType(channels, data.tactic, data.unit_price_type);
  return (
    <div className="rounded-lg border-[1px] border-gray-300 bg-white p-4 shadow-lg">
      Unit Price is {Math.abs(diff).toFixed(1)}% {diff > 0 ? 'above' : 'below'} the rate card value
      of {unitPriceTextValue(data, rate.unitPrice)}
    </div>
  );
}

function unitPriceTextValue(data: LineItemTableData | undefined, value: number | null | undefined) {
  if (value == null) return 'Filled after Channel';
  return formatCurrency(data?.unit_price_type?.name === 'CPM' ? value * 1000 : value);
}

function determineUnitPriceDiff({ data, value, channels }: RateCardDiffParams) {
  if (data == null || !data.unit_price_type || !data.tactic || value == null) return undefined;
  const rate = rateForTacticUnitPriceType(channels, data.tactic, data.unit_price_type);
  if (rate.unitPrice === 0 || value === rate.unitPrice) return undefined;
  return ((value - rate.unitPrice) / rate.unitPrice) * 100;
}

type RateCartValueProps = {
  disabled: boolean;
  value: string;
  diff: number | undefined;
};

function RateCardValue({ disabled, value, diff }: RateCartValueProps) {
  return (
    <div className={clsx('h-full w-full p-1', disabled ? 'opacity-35' : '')}>
      <div className="shadow-custom-inset flex h-full w-full items-center justify-between overflow-x-clip rounded border-[1px] border-blue-300 bg-[#3F9FFD0C] px-2">
        <div className="mr-2 flex flex-shrink-0 items-center">
          <RateCardIcon />
          <div className="ml-2 text-sm">{value}</div>
        </div>
        <div className="ml-1 min-w-[20px]">{diff && <RateCardDiff change={diff} />}</div>
      </div>
    </div>
  );
}

function RateCardDiff({ change }: { change: number }) {
  return (
    <div
      className={clsx(
        'flex items-center border-[1px] px-[2px] text-xs',
        change < 0 ? 'border-red-500 text-red-500' : 'border-green-500 text-green-500'
      )}>
      <FontAwesomeIcon icon={change < 0 ? faCaretDown : faCaretUp} className="mr-[2px]" />
      <div>{change.toFixed(1)}%</div>
    </div>
  );
}

export function EstimatedUnitsRenderer({ data, value }: CellRendererProps<number>) {
  return (
    <CalculatedValue
      disabled={data?.unit_price_type == null}
      value={value ? value.toLocaleString() : ''}
    />
  );
}

export function UnitCostsRenderer({ data, value }: CellRendererProps<number>) {
  return (
    <CalculatedValue
      disabled={data?.unit_price_type == null}
      value={value ? formatCurrency(value) : ''}
    />
  );
}

export function MediaBudgetRenderer({ data, value }: CellRendererProps<number>) {
  return (
    <CalculatedValue
      disabled={data?.unit_price_type == null}
      value={value ? formatCurrency(value) : ''}
    />
  );
}

function CalculatedValue({ value, disabled }: { disabled: boolean; value: string }) {
  return (
    <div className={clsx('h-full w-full p-1', disabled ? 'opacity-35' : '')}>
      <div className="shadow-custom-inset flex h-full w-full items-center justify-between rounded  bg-[#F3F4F6] px-2">
        <FontAwesomeIcon icon={faCalculator} className="text-lg text-gray-600" />
        <div className="text-sm">{disabled ? 'Auto Calculated' : value || ''}</div>
      </div>
    </div>
  );
}

// Safely determine what the correct column value is to render
export function getValueString(value: unknown): string | undefined {
  if (value != null) {
    if (typeof value === 'object' && 'name' in value && typeof value.name === 'string') {
      return value.name;
    }
    if (Array.isArray(value)) {
      return value
        .map(v => getValueString(v))
        .filter(v => v != null)
        .join(', ');
    }
    if (typeof value === 'string') {
      return value;
    }
    return undefined;
  }
  return '';
}
