import React from 'react';

export function RateCardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M14.9328 3.75005L9.50781 0.625049C9.10781 0.400049 8.60781 0.400049 8.18281 0.625049L2.78281 3.75005C2.38281 3.97505 2.13281 4.42505 2.13281 4.87505V11.125C2.13281 11.6 2.38281 12.025 2.78281 12.25L8.18281 15.375C8.38281 15.5 8.60781 15.55 8.83281 15.55C9.05781 15.55 9.28281 15.5 9.48281 15.375L14.9328 12.25C15.3328 12.025 15.5828 11.575 15.5828 11.125V4.87505C15.5828 4.40005 15.3328 3.97505 14.9328 3.75005ZM3.35781 4.72505L8.75781 1.60005C8.78281 1.57505 8.80781 1.57505 8.85781 1.57505C8.88281 1.57505 8.93281 1.57505 8.95781 1.60005L13.8078 4.40005L11.2828 5.92505C11.2328 5.87505 11.1578 5.82505 11.1078 5.80005L9.65781 4.95005C9.18281 4.67505 8.58281 4.67505 8.08281 4.95005L6.60781 5.80005C6.13281 6.07505 5.83281 6.60005 5.83281 7.12505V8.87505C5.83281 8.95005 5.83281 9.02505 5.85781 9.10005L3.23281 10.6V4.87505C3.25781 4.80005 3.30781 4.75005 3.35781 4.72505ZM10.7328 7.12505V8.87505C10.7328 9.02505 10.6578 9.17505 10.5328 9.25005L9.08281 10.1C8.95781 10.175 8.78281 10.175 8.65781 10.1L7.18281 9.25005C7.05781 9.17505 6.98281 9.02505 6.98281 8.87505V7.12505C6.98281 6.97505 7.05781 6.82505 7.18281 6.75005L8.63281 5.90005C8.70781 5.85005 8.78281 5.85005 8.85781 5.85005C8.93281 5.85005 9.00781 5.87505 9.08281 5.90005L10.5328 6.75005C10.6578 6.82505 10.7328 6.97505 10.7328 7.12505ZM14.3578 11.275L8.95781 14.4C8.90781 14.425 8.83281 14.425 8.78281 14.4L3.83281 11.575L6.43281 10.1C6.48281 10.15 6.55781 10.2 6.60781 10.225L8.05781 11.075C8.30781 11.225 8.58281 11.3 8.83281 11.3C9.08281 11.3 9.38281 11.225 9.60781 11.075L11.0578 10.225C11.5328 9.95005 11.8328 9.42505 11.8328 8.90005V7.15005C11.8328 7.07505 11.8328 7.00005 11.8078 6.92505L14.4328 5.35005V11.175C14.4578 11.2 14.4328 11.25 14.3578 11.275Z"
        fill="#3F9FFD"
      />
    </svg>
  );
}
