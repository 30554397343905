import React from 'react';

export function LoadingIcon() {
  return (
    <div className="ml-1 flex items-end justify-center space-x-2 pb-[5px]">
      <div className="h-1 w-1 animate-pulse rounded-full bg-black [animation-delay:-0.3s]"></div>
      <div className="h-1 w-1 animate-pulse rounded-full bg-black [animation-delay:-0.15s]"></div>
      <div className="h-1 w-1 animate-pulse rounded-full bg-black"></div>
    </div>
  );
}
