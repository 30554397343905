import React from 'react';
import { LineItemsTable, LineItemTableData } from '../line-items/line-items-table';
import { useStore } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import { PacingDrawer } from '../line-items/pacing-drawer';
import { Campaign } from 'shared/src/types';
import { formatDateString } from 'shared/src/date-utils';
import { LineItemsAllowed } from './allowed-actions-info';
import { faList, faUser } from '@fortawesome/pro-light-svg-icons';
import { StrategyMetrics } from './strategy-metrics';
import { useStrategyContext } from '../line-items/strategy-context';
import { CombinedStrategy } from '../../store/strategy-combiner';
import { PageContentSection, ExpandableSidebarSection, PageSection } from '../../components/layout';
import { DetailItem } from '../../components/detail-item';

export function StrategyOverview() {
  const { strategy, campaign } = useStrategyContext();

  return (
    <>
      <PageSection>
        <PageContentSection>
          <StrategyMetrics campaign={campaign} />
          <StrategyPerformance />
          <LineItemsSection campaign={campaign} strategy={strategy} />
        </PageContentSection>
        <ExpandableSidebarSection
          sections={[
            {
              name: 'details',
              header: 'Details',
              icon: faList,
              render: () => <DetailsSection campaign={campaign} />
            },
            {
              name: 'users',
              header: 'Role Assignments',
              icon: faUser,
              render: () => <AssignmentsSection campaign={campaign} />
            }
          ]}
        />
      </PageSection>
      <PacingDrawer strategy={strategy} />
    </>
  );
}

type Props = {
  campaign: Campaign | undefined;
  strategy: CombinedStrategy;
};

function LineItemsSection({ campaign, strategy }: Props) {
  const [selectedRows, setSelectedRows] = React.useState<LineItemTableData[]>([]);
  const duplicateLineItems = useStore(state => state.duplicateLineItems);

  function handleDuplicate() {
    if (selectedRows.length > 0) duplicateLineItems(selectedRows);
  }

  function handleKeyboardDuplicate(currentRow: LineItemTableData) {
    const otherRows = selectedRows.filter(row => row.id !== currentRow.id);
    duplicateLineItems([...otherRows, currentRow]);
  }

  return (
    <div className="mt-4 flex h-full flex-col pb-16">
      <LineItemsAllowed campaign={campaign} />
      <div className="flex items-center justify-between py-2">
        <div className="flex">
          <div className="pr-16 text-2xl font-bold">Line Items</div>
          <DuplicateButton selectedRows={selectedRows} onClick={handleDuplicate} />
        </div>
      </div>
      <LineItemsTable
        lineItems={strategy.line_items ?? []}
        mediaTraders={campaign?.team.mediaTraders}
        setSelectedRows={setSelectedRows}
        handleKeyboardDuplicate={handleKeyboardDuplicate}
      />
    </div>
  );
}

function DetailsSection({ campaign }: { campaign: Campaign | undefined }) {
  return (
    <div className="flex flex-col">
      <DetailItem label="CN Number" value={campaign?.Deal_Number} />
      <DetailItem label="Created on" value={formatDateString(campaign?.Created_Time)} />
      <DetailItem label="Start Date" value={formatDateString(campaign?.Flight_Date)} />
      <DetailItem label="End Date" value={formatDateString(campaign?.End_Date)} />
      <DetailItem label="Account" value={campaign?.Account_Name.name} />
      <DetailItem label="Agency" value={campaign?.Agency?.name || '-'} />
      <DetailItem label="Campaign Goals" value={campaign?.Goals_KPIs} />
      <DetailItem label="Campaign Stage" value={campaign?.Stage} />
      <DetailItem
        label="New Business"
        value={(campaign?.New_Business && (campaign?.New_Business ? 'Yes' : 'No')) || '-'}
      />
    </div>
  );
}

function AssignmentsSection({ campaign }: { campaign: Campaign | undefined }) {
  return (
    <div className="flex flex-col">
      <DetailItem label="Account Manager" value={campaign?.team.leadAccountManager?.name || null} />
      <DetailItem
        label="Senior Media Trader"
        value={campaign?.team.seniorMediaTrader?.name || null}
      />
      <DetailItem
        label="Client Success Directors"
        value={campaign?.team.clientSuccessDirector?.name}
      />
      <DetailItem label="Analyitcs" value={campaign?.team.analytics?.name || null} />
      <DetailItem
        label="Ad Operations"
        value={campaign?.team.adOps.map(mt => mt.name).join(', ') || null}
      />
      <DetailItem
        label="Media Traders"
        value={campaign?.team.mediaTraders.map(mt => mt.name).join(', ') || null}
      />
    </div>
  );
}

function StrategyPerformance() {
  return (
    <div className="mb-8 flex flex-col">
      <div className="mb-4 pr-16 text-2xl font-bold">Campaign Performance</div>
      <div className="flex h-36 items-center justify-center rounded-xl bg-gray-100 align-middle">
        <div className="text-sm font-light">Check back here for performance data once live</div>
      </div>
    </div>
  );
}

function DuplicateButton(props: { selectedRows: LineItemTableData[]; onClick: () => void }) {
  return (
    <div
      className={`transform transition-opacity duration-1000 ease-in-out ${
        props.selectedRows.length > 0 ? 'cursor-pointer opacity-100' : 'opacity-0'
      }`}>
      <div
        data-tooltip-id="duplicate-button"
        className="rounded text-blue-500 shadow-lg"
        onClick={props.onClick}>
        <FontAwesomeIcon icon={faClone} />
      </div>
      <Tooltip id="duplicate-button" place="top">
        Duplicate Line Items (Ctrl + D)
      </Tooltip>
    </div>
  );
}
