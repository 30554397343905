import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { clsx } from 'clsx';

type Props = { children: React.ReactNode };

export function TopSection({ children }: Props) {
  return (
    <div className="flex h-[150px] flex-col border-b-[1px] border-gray-200 px-6">{children}</div>
  );
}

export function PageSection({ children }: Props) {
  return <div className="flex w-full">{children}</div>;
}

export function PageContentSection({ children }: Props) {
  return (
    <div className="flex h-[calc(100vh-150px)] w-full flex-col overflow-y-auto px-5 py-6">
      {children}
    </div>
  );
}

type Section = {
  name: string;
  header: string;
  icon: IconDefinition;
  render: (setExpanded: (expanded: string) => void) => React.ReactNode;
};

export function ExpandableSidebarSection({ sections }: { sections: Section[] }) {
  const [expanded, setExpanded] = React.useState<string | null>(sections[0]?.name || null);

  function setExpandedSection(sectionName: string) {
    if (!sections.map(s => s.name).includes(sectionName)) {
      console.error(`Section ${sectionName} not found`);
      return;
    }
    if (expanded === sectionName) setExpanded(null);
    else setExpanded(sectionName);
  }

  return (
    <>
      <div className={clsx('flex-shrink-0 transition-all duration-500', expanded ? 'w-96' : 'w-0')}>
        {sections.map(({ name, header, render }) => (
          <RightSidePanel
            key={name}
            expanded={expanded === name}
            header={header}
            close={() => setExpanded(null)}>
            {render(setExpandedSection)}
          </RightSidePanel>
        ))}
      </div>
      <div className="z-20 flex w-10 flex-shrink-0 flex-col items-center border-l-[1px] border-gray-200 bg-gray-50">
        {sections.map(({ name, icon }) => (
          <FontAwesomeIcon
            key={name}
            className="my-3 h-5 w-5 cursor-pointer"
            icon={icon}
            onClick={() => setExpandedSection(name)}
          />
        ))}
      </div>
    </>
  );
}

type RightSidePanelProps = {
  expanded: boolean;
  header: string;
  close: () => unknown;
  children: React.ReactNode;
};

function RightSidePanel({ expanded, header, close, children }: RightSidePanelProps) {
  return (
    <div
      className={clsx(
        'fixed bottom-0 z-10 flex h-[calc(100vh-150px)] w-96 bg-gray-50 transition-all duration-500',
        expanded ? 'right-[40px] border-l-[1px] border-gray-200' : 'right-[-384px]'
      )}>
      <div className="w-full">
        <div className="flex h-16 items-center">
          <div className="ml-4 text-xl">{header}</div>
        </div>
        <div className="ml-[-16px] flex w-full items-center">
          <div
            className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border-[1px] border-gray-200 bg-gray-50"
            onClick={close}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
          <div className="ml-2 mr-2 h-[2px] flex-1 bg-gray-200" />
        </div>
        <div className="h-[calc(100vh-246px)] overflow-y-auto p-4">{children}</div>
      </div>
    </div>
  );
}
