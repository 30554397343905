import { CombinedLineItem, CombinedMediaBuy } from '../../store/strategy-combiner';
import { useStore } from '../../store/store';
import { formatMoney } from 'shared/src/money-utils';
import { format } from 'date-fns';
import React from 'react';
import { formatDate } from 'shared/src/date-utils';
import { LineItemErrors } from './line-item-errors';
import { User } from 'shared/src/user-types';
import { UTCDate } from '@date-fns/utc';

export type HistoryLineItem = {
  name: string;
  budget: number;
  audience: string;
  ad_formats: string;
  tactic_id: number;
  start_date: Date;
  end_date: Date;
  media_traders: User[];
};

export type HistoryLineItemUpdate = {
  lineItem: CombinedLineItem;
  delta: {
    name?: { old: string; new: string };
    channel_id?: { old: number; new: number };
    tactic_id?: { old: number; new: number };
    unit_price_type_id?: { old: number; new: number };
    geo?: { old: string; new: string };
    targeting?: { old: string; new: string };
    price?: { old: number; new: number };
    audience?: { old: string; new: string };
    start_date?: { old: UTCDate; new: UTCDate };
    end_date?: { old: UTCDate; new: UTCDate };
    media_traders?: { old: User[]; new: User[] };
    is_deleted?: { old: boolean; new: boolean };
  };
};

export type HistoryMediaBuy = {
  name: string;
  budget: number;
  start_date: UTCDate;
  end_date: UTCDate;
};

export type HistoryMediaBuyUpdate = {
  mediaBuy: CombinedMediaBuy;
  delta: {
    budget?: { old: number; new: number };
    start_date?: { old: UTCDate; new: UTCDate };
    end_date?: { old: UTCDate; new: UTCDate };
  };
};

export function NewHistoryLineItem({ lineItem }: { lineItem: HistoryLineItem }) {
  return (
    <div className="mb-1">
      <div className="flex flex-wrap rounded-md bg-gray-100 px-2 font-light">
        <ItemProperty label="Name" value={lineItem.name} />
        <ItemProperty label="Media Trader" value={lineItem.media_traders?.join(', ') || '-'} />
        <ItemProperty label="Budget" value={formatMoney(lineItem.budget)} />
        <ItemProperty label="Audience" value={lineItem.audience} />
        <ItemProperty label="Ad Formats" value={lineItem.ad_formats} />
        <ItemProperty label="Tactic" value={lineItem.tactic_id.toString()} />
        <ItemProperty label="Start Date" value={format(lineItem.start_date, 'MMM do yyyy')} />
        <ItemProperty label="End Date" value={format(lineItem.end_date, 'MMM do yyyy')} />
      </div>
    </div>
  );
}

export function NewHistoryMediaBuy({ mediaBuy }: { mediaBuy: HistoryMediaBuy }) {
  return (
    <div className="mb-1">
      <div className="flex flex-wrap rounded-md bg-gray-100 px-2 font-light">
        <ItemProperty label="Name" value={mediaBuy.name} />
        <ItemProperty label="Budget" value={formatMoney(mediaBuy.budget)} />
        <ItemProperty label="Start Date" value={format(mediaBuy.start_date, 'MMM do yyyy')} />
        <ItemProperty label="End Date" value={format(mediaBuy.end_date, 'MMM do yyyy')} />
      </div>
    </div>
  );
}

type NewLineItemProps = { lineItem: CombinedLineItem };

export function NewLineItem({ lineItem }: NewLineItemProps) {
  const removeLineItem = useStore(state => state.removeLineItem);
  // We need to ensure that a media traders name is still displayed even if they have moved from the team managing this
  // campaign
  return (
    <div className="mb-10 px-6">
      <div className="mb-4 flex items-center">
        <div className="mr-4 font-bold">{lineItem.name}</div>
        <div className="rounded-lg bg-gray-100 px-2 py-[3px] text-xs font-light text-gray-500">
          New
        </div>
        <div className="flex-1" />
        <div
          className="text-sm font-light text-red-500 hover:cursor-pointer hover:underline"
          onClick={() => removeLineItem(lineItem.strategy_id, lineItem.id)}>
          Discard
        </div>
      </div>
      <div className="flex flex-wrap rounded-md bg-gray-100 px-2 font-light">
        <ItemProperty label="Name" value={lineItem.name} />
        <ItemProperty
          label="Media Trader"
          value={lineItem.media_traders?.map(mt => mt.name).join(',') || '-'}
        />
        <ItemProperty label="Channel" value={lineItem.channel?.name} />
        <ItemProperty label="Tactic" value={lineItem.tactic?.name} />
        <ItemProperty label="Unit Price Type" value={lineItem.unit_price_type?.name} />
        <ItemProperty label="Geo" value={lineItem.geo} />
        <ItemProperty label="Targeting" value={lineItem.targeting} />
        <ItemProperty label="Audience" value={lineItem.audience} />
        <ItemProperty label="Ad Formats" value={lineItem.ad_formats} />
        <ItemProperty label="Start Date" value={formatDate(lineItem.start_date, 'MMM do yyyy')} />
        <ItemProperty label="End Date" value={formatDate(lineItem.end_date, 'MMM do yyyy')} />
        <ItemProperty label="Price" value={formatMoney(lineItem.price)} />
        <ItemProperty label="Pacing Type" value={lineItem.pacing_type} />
        {lineItem.pacing_details && (
          <div className="mr-6 flex py-2 text-sm">
            <div className="mr-2 lowercase text-gray-500">pacing details:</div>
            <div className="font-normal">
              <div className="flex flex-col text-gray-700">
                {lineItem.pacing_details.blocks.map((block, idx) => (
                  <div key={idx} className="flex">
                    <div className="mr-2">
                      {formatDate(block.start_date)} to {formatDate(block.end_date)}:
                    </div>
                    <div>{formatMoney(block.price)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <LineItemErrors lineItem={lineItem} />
    </div>
  );
}

type NewMediaBuyProps = { mediaBuy: CombinedMediaBuy };

export function NewMediaBuy({ mediaBuy }: NewMediaBuyProps) {
  return (
    <div className="mb-10 px-6">
      <div className="mb-4 flex items-center">
        <div className="mr-4 font-bold">{mediaBuy.name}</div>
        <div className="rounded-lg bg-gray-100 px-2 py-[3px] text-xs font-light text-gray-500">
          New
        </div>
        <div className="flex-1" />
        <div
          className="text-sm font-light text-red-500 hover:cursor-pointer hover:underline"
          onClick={() => {}}>
          Discard
        </div>
      </div>
      <div className="flex flex-wrap rounded-md bg-gray-100 px-2 font-light">
        <ItemProperty label="Name" value={mediaBuy.name} />
        <ItemProperty label="Budget" value={formatMoney(mediaBuy.budget)} />
        <ItemProperty label="Start Date" value={format(mediaBuy.start_date, 'MMM do yyyy')} />
        <ItemProperty label="End Date" value={format(mediaBuy.end_date, 'MMM do yyyy')} />
      </div>
    </div>
  );
}

function ItemProperty({ label, value }: { label: string; value: string | undefined }) {
  return (
    <div className="mr-6 flex py-2 text-sm">
      <div className={`mr-2 lowercase text-gray-500 ${value != undefined ? '' : 'text-red-500'}`}>
        {label}:
      </div>
      <div className="font-normal">{value || '-'}</div>
    </div>
  );
}
