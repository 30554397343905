import { CombinedStrategy } from '../../store/strategy-combiner';
import { DraftStrategyChanges, FinalisedStrategyChanges } from 'shared/src/types';
import { FinalisedLineItemChange, LineItemChangeDraft } from 'shared/src/line-item-types';
import { mapValues } from 'lodash';

export function changesCount(strategy: CombinedStrategy) {
  const newLineItemsCnt = strategy.line_items.filter(li => li.state.type === 'new').length;
  const updatedLineItemsCnt = strategy.line_items
    .map(li => (li.state.type === 'update' ? Object.keys(li.state.dirty).length : 0))
    .reduce((a, b) => a + b, 0);

  const newMediaBuysCnt = strategy.line_items
    .flatMap(lineItem => lineItem.media_buys)
    .filter(mediaBuy => mediaBuy.state === 'new').length;

  const updatedMediaBuys = strategy.line_items
    .flatMap(lineItem => lineItem.media_buys)
    .filter(mediaBuy => mediaBuy.state === 'updated');

  const updatedMediaBuysCnt = updatedMediaBuys
    .map(mediaBuy => Object.keys(mediaBuy.dirty).length)
    .reduce((a, b) => a + b, 0);

  return newLineItemsCnt + updatedLineItemsCnt + newMediaBuysCnt + updatedMediaBuysCnt;
}

export function changesAmount(strategy: CombinedStrategy) {
  const newSpend = strategy.line_items
    .filter(lineItem => lineItem.state.type === 'new')
    .map(item => item.price)
    .reduce((a, b) => (a || 0) + (b || 0), 0);
  const updatedSpend = strategy.line_items
    .map(li => (li.state.type === 'update' ? li.state.dirty : null))
    .filter(Boolean)
    .filter(dirty => dirty.price != null)
    .map(dirty => dirty.price?.new! - dirty.price?.old!)
    .reduce((a, b) => a + b, 0);
  return (newSpend || 0) + updatedSpend;
}

export function getChanges(strategy: CombinedStrategy) {
  const updatedLineItems = strategy.line_items.filter(lineItem => lineItem.state.type === 'update');
  const createdLineItems = strategy.line_items.filter(lineItem => lineItem.state.type === 'new');
  const updatedMediaBuys = strategy.line_items
    .flatMap(lineItem => lineItem.media_buys)
    .filter(mediaBuy => mediaBuy.state === 'updated');
  const createdMediaBuys = strategy.line_items
    .flatMap(lineItem => lineItem.media_buys)
    .filter(mediaBuy => mediaBuy.state === 'new');
  return {
    lineItems: { updated: updatedLineItems, created: createdLineItems },
    mediaBuys: { updated: updatedMediaBuys, created: createdMediaBuys }
  };
}

export function convertStrategyChanges(changes: DraftStrategyChanges): FinalisedStrategyChanges {
  const { line_items, media_buys } = changes;
  return {
    media_buys,
    line_items: mapValues(line_items, convertLineItem)
  };
}

function convertLineItem(lineItem: LineItemChangeDraft): FinalisedLineItemChange {
  // Line item should already have been validated at this point
  return FinalisedLineItemChange.parse(lineItem);
}
