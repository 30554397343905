import React from 'react';
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { CombinedLineItem } from '../../store/strategy-combiner';
import { PendingChangesButton } from '../pending-changes/pending-changes-button';
import { Campaign } from 'shared/src/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faChartLine,
  faClipboardListCheck,
  faEye,
  faHouse
} from '@fortawesome/pro-light-svg-icons';
import { useStrategyContext } from './strategy-context';
import {
  ExpandableSidebarSection,
  PageContentSection,
  PageSection,
  TopSection
} from '../../components/layout';
import { NavItem } from '../../components/navitem';
import { formatCurrency, formatCurrencyNoDecimal } from '../../components/table-utils';
import { MetricItem } from '../../components/metric-item';
import { formatDate } from 'shared/src/date-utils';
import { MediaBuysAllowed } from '../strategy/allowed-actions-info';
import { NewMediaBuyButton } from '../media-buys/new-media-buy-modal';
import { MediaBuysTable } from '../media-buys/media-buys-table';
import { ViewLineItemPacing } from './view-line-item-pacing';
import {
  calcAdjustedUnitCost,
  calcEstimatedUnits,
  calcMediaBudget
} from 'shared/src/line-item-utils';
import { MediaBuyGraph } from '../media-buys/media-buy-graph';
import { useLineItemMetrics } from '../media-buys/use-media-buy-metrics';

export function LineItemPage() {
  const { strategy, campaign } = useStrategyContext();
  const { lineItemId } = useParams();
  const navigate = useNavigate();
  const lineItem = strategy.line_items.find(li => li.id === lineItemId);

  if (!lineItem) {
    // If a new line-item change is rejected while on the line-item-page then lineItem will not be set.
    // We handle this here.
    navigate(`/campaigns/${campaign?.id}/strategy/performance`);
    console.log(`Lineitem is not set, redirecting back to campaign strategy`);
    return null;
  }

  return (
    <div className="flex w-full flex-col">
      <TopSection>
        <Breadcrumbs campaign={campaign} name={lineItem.name} />
        <div className="flex-1" />
        <div className="flex items-end">
          <NavItem to="overview" label="Overview" />
        </div>
      </TopSection>
      <PendingChangesButton />
      <Routes>
        <Route
          path="overview"
          element={<LineItemOverview campaign={campaign} lineItem={lineItem} />}
        />
      </Routes>
    </div>
  );
}

type LineItemOverviewProps = {
  campaign: Campaign | undefined;
  lineItem: CombinedLineItem;
};

function LineItemOverview({ campaign, lineItem }: LineItemOverviewProps) {
  const { data } = useLineItemMetrics(lineItem.id);
  return (
    <PageSection>
      <PageContentSection>
        <LineItemMetrics campaign={campaign} lineItem={lineItem} />
        <MediaBuyGraph data={data} lineItem={lineItem} mediaBuys={lineItem.media_buys} />
        <div className="flex flex-col py-2 pb-16 ">
          <MediaBuysAllowed lineItem={lineItem} campaign={campaign} />
          <div className="flex items-center justify-between py-2">
            <div className="pr-16 text-lg font-bold">Media Buys</div>
            <NewMediaBuyButton lineItem={lineItem} campaign={campaign} />
          </div>
          <MediaBuysTable mediaBuys={lineItem.media_buys ?? []} />
        </div>
      </PageContentSection>
      <ExpandableSidebarSection
        sections={[
          {
            name: 'execution',
            header: 'Execution Instructions',
            icon: faClipboardListCheck,
            render: setExpanded => (
              <LineItemInstructions lineItem={lineItem} setExpanded={setExpanded} />
            )
          },
          ...(lineItem.pacing_type === 'custom'
            ? [
                {
                  name: 'pacing',
                  header: 'Pacing Schedule',
                  icon: faChartLine,
                  render: () => <ViewLineItemPacing lineItem={lineItem} />
                }
              ]
            : [])
        ]}
      />
    </PageSection>
  );
}

type LineItemInstructionsProps = {
  lineItem: CombinedLineItem;
  setExpanded: (expanded: string) => void;
};

function LineItemInstructions({ lineItem, setExpanded }: LineItemInstructionsProps) {
  const mediaBudget = calcMediaBudget(lineItem);
  const unitCost = calcAdjustedUnitCost(lineItem);
  const estimatedUnits = calcEstimatedUnits(lineItem);
  return (
    <div className="rounded-lg bg-gray-200 p-2">
      <InstructionSection>
        <InstructionItem
          label="Assigned Media Trader"
          value={lineItem.media_traders?.map(mt => mt.name).join(', ')}
        />
      </InstructionSection>
      <InstructionSection>
        <DateItem label="Start Date" date={lineItem.start_date} />
        <DateItem label="End Date" date={lineItem.end_date} />
      </InstructionSection>
      <InstructionSection>
        <div className="flex basis-1/2 flex-col gap-3">
          <InstructionItem
            label="Channel : Tactic"
            value={`${lineItem.channel?.name} : ${lineItem.tactic?.name}`}
          />
          <InstructionItem
            label="Platforms"
            value={lineItem.media_platforms?.map(platform => platform.name).join(', ')}
          />
        </div>
        <div className="flex basis-1/2 flex-col gap-3">
          <InstructionItem label="Price Type" value={lineItem.unit_price_type?.name} />
          <InstructionItem
            label="Estimated Units"
            value={estimatedUnits ? estimatedUnits.toLocaleString() : undefined}
          />
        </div>
      </InstructionSection>
      <InstructionSection>
        <InstructionItem
          label="Target Margin"
          value={
            lineItem.target_margin
              ? `${parseFloat((lineItem.target_margin * 100).toFixed(2)).toString()}%`
              : '-'
          }
        />
        <div className="flex flex-col gap-3">
          <InstructionItem
            label="Media Budget"
            value={mediaBudget ? formatCurrencyNoDecimal(mediaBudget) : undefined}
          />
          <InstructionItem
            label="Target Unit Cost"
            value={unitCost ? formatCurrency(unitCost) : undefined}
          />
        </div>
      </InstructionSection>
      <InstructionSection>
        <InstructionItem label="Pacing Type" value={lineItem.pacing_type} />
        {lineItem.pacing_type === 'custom' && (
          <>
            <div className="mr-2 w-[1px] bg-gray-200" />
            <ViewPacingSchedule openPacing={() => setExpanded('pacing')} />
          </>
        )}
      </InstructionSection>
      <InstructionSection>
        <InstructionItem label="Targeting" value={lineItem.targeting} />
      </InstructionSection>
      <InstructionSection>
        <InstructionItem label="Audience" value={lineItem.audience} />
      </InstructionSection>
      <InstructionSection>
        <InstructionItem label="Geo" value={lineItem.geo} />
      </InstructionSection>
    </div>
  );
}

function ViewPacingSchedule({ openPacing }: { openPacing: () => void }) {
  return (
    <div className="my-2 flex-1 flex-col">
      <div className="text-sm font-light text-gray-500">Pacing Schedule</div>
      <div
        className="mt-1 flex max-w-max cursor-pointer items-center justify-center rounded bg-blue-600 px-2 py-[2px] text-sm font-light text-white"
        onClick={openPacing}>
        <FontAwesomeIcon icon={faEye} className="mr-1" />
        <div>View Details</div>
      </div>
    </div>
  );
}

function InstructionSection({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex gap-3 border-b-[1px] border-gray-300 py-3 first-of-type:pt-0 last-of-type:border-b-0 last-of-type:pb-0">
      {children}
    </div>
  );
}

type BreadcrumbsProps = {
  campaign: Campaign | undefined;
  name: string | undefined;
};

function Breadcrumbs({ campaign, name }: BreadcrumbsProps) {
  return (
    <div className="mt-6 flex flex-col">
      <div className="flex">
        <Link className="mr-1 flex items-center hover:underline" to="/campaigns">
          <FontAwesomeIcon icon={faHouse} className="mr-1" />
          <div>All Campaigns</div>
        </Link>
        <div className="mr-1">/</div>
        <Link
          className="mr-1 hover:underline"
          to={`/campaigns/${campaign?.id}/strategy/performance`}>
          {campaign ? campaign.Deal_Name : '-'}
        </Link>
      </div>
      <div className="mt-2 text-2xl font-bold">{name ? name : '-'}</div>
    </div>
  );
}

type LineItemMetricsProps = {
  campaign: Campaign | undefined;
  lineItem: CombinedLineItem;
};

function LineItemMetrics({ campaign, lineItem }: LineItemMetricsProps) {
  const mediaBudget = calcMediaBudget(lineItem);

  return (
    <div className="mb-8 flex items-center justify-between">
      <MetricItem name="Campaign Stage" value={campaign?.Stage} />
      <MetricItem
        name="Budget"
        value={mediaBudget ? formatCurrencyNoDecimal(mediaBudget) : undefined}
      />
      <MetricItem name="Margin" />
      <MetricItem name="Revenue" />
      <MetricItem name="Spend Pacing" />
      <MetricItem name="Delivery Pacing" noBorder />
    </div>
  );
}

export function DateItem({ label, date }: { label: string; date: Date | undefined }) {
  return (
    <div className="flex flex-1 rounded bg-gray-100 px-2 py-2">
      <FontAwesomeIcon className="mr-1" icon={faCalendarDays} />
      <div className="flex flex-col">
        <div className="font-bold">{date ? formatDate(date, 'MM/dd/yyyy') : '-'}</div>
        <div className="text-xs font-light text-gray-500">{label}</div>
      </div>
    </div>
  );
}

type InstructionItemProps = {
  label: string;
  value: string | React.ReactNode | null | undefined;
};

export function InstructionItem({ label, value }: InstructionItemProps) {
  return (
    <div className="flex-1 flex-col gap-3 rounded bg-gray-100 px-2 py-2">
      <div className="mt-1 font-bold">{value || '-'}</div>
      <div className="text-xs font-light text-gray-500">{label}</div>
    </div>
  );
}
