import React from 'react';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
};

export default function Drawer({ open, setOpen, children }: Props) {
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-20">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700">
              {children}
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
