import React from 'react';
import { Campaign } from 'shared/src/types';
import {
  checkLineItemCreationAllowed,
  checkMediaBuyCreationAllowed
} from './allowed-actions-utils';
import { CombinedLineItem } from '../../store/strategy-combiner';

type Props = {
  campaign: Campaign | undefined;
};

export function LineItemsAllowed({ campaign }: Props) {
  if (!campaign) return null;

  const result = checkLineItemCreationAllowed(campaign);
  if (result.type === 'allowed') return null;

  return <Alert {...result} />;
}

export function MediaBuysAllowed({ campaign, lineItem }: Props & { lineItem: CombinedLineItem }) {
  if (!campaign) return null;

  const result = checkMediaBuyCreationAllowed(campaign, lineItem);
  if (result.type === 'allowed') return null;

  return <Alert {...result} />;
}

type AlertProps = {
  level: 'error' | 'warning';
  header: string;
  message: string;
};

export function Alert({ level, header, message }: AlertProps) {
  return (
    <div
      className={`mb-4 rounded-xl border-l-8 ${level === 'warning' ? 'border-amber-500 bg-amber-50' : 'border-red-500 bg-red-50'} px-4 py-8`}>
      <div className="mb-2 font-bold">{header}</div>
      <div className={level === 'warning' ? 'text-amber-500' : 'text-red-500'}>{message}</div>
    </div>
  );
}
