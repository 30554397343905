import { useEffect, useState } from 'react';
import { client } from '../../utils/trpc-client';
import { MediaBuyMetric } from 'shared/src/metrics-types';

export type MediaBuyMetrics = {
  data: MediaBuyMetric[];
  loading: boolean;
};

export function useMediaBuyMetrics(media_buy_id: string): MediaBuyMetrics {
  const [mediaBuyMetrics, setMediaBuyMetrics] = useState<MediaBuyMetric[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        setMediaBuyMetrics(await client.mediaBuyMetrics.query({ media_buy_id, bucket: 'day' }));
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [media_buy_id]);

  return { data: mediaBuyMetrics, loading };
}

export function useLineItemMetrics(line_item_id: string): MediaBuyMetrics {
  const [mediaBuyMetrics, setMediaBuyMetrics] = useState<MediaBuyMetric[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        setMediaBuyMetrics(await client.lineItemMetrics.query({ line_item_id, bucket: 'day' }));
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [line_item_id]);

  return { data: mediaBuyMetrics, loading };
}
