import React, { Fragment } from 'react';
import { formatMoney } from 'shared/src/money-utils';
import { CombinedLineItem } from '../../store/strategy-combiner';
import { Day, getCumulativeSpend } from './pacing-helpers';
import { PacingChart } from './pacing-chart';
import { formatDate } from 'shared/src/date-utils';
import { DateItem } from './line-item-page';
import { calcPacingBlockBudget } from 'shared/src/line-item-utils';

type Props = {
  lineItem: CombinedLineItem;
};

export function ViewLineItemPacing({ lineItem }: Props) {
  // TODO[mk] - show a message to the user if the start date and end date are not set
  if (!lineItem.pacing_details || !lineItem.start_date || !lineItem.end_date || !lineItem.price)
    return null;

  const { blocks } = lineItem.pacing_details;

  const data = getCumulativeSpend(lineItem.start_date, lineItem.end_date, blocks).map(day =>
    calcBudgetFromPrice(lineItem, day)
  );

  return (
    <div className="flex h-full flex-col ">
      <div className="px-1 py-2">
        <div className="mt-4 flex justify-between">
          <DateItem date={lineItem.start_date} label="Start Date" />
          <DateItem date={lineItem.end_date} label="End Date" />
        </div>
      </div>
      <div className="mt-2 flex-1 overflow-y-auto">
        <PacingChart data={data} />
        <div className="px-1 pb-8 pt-2">
          <div className="mb-2">
            <div className="grid grid-cols-[1fr_1fr_1fr] gap-2 font-light">
              <div className="text-sm font-bold">Start Date</div>
              <div className="text-sm font-bold">End Date</div>
              <div className="text-sm font-bold">Media Budget</div>
              {blocks.map((block, idx) => (
                <Fragment key={idx}>
                  <div>{formatDate(block.start_date, 'MM/dd/yyyy')}</div>
                  <div>{formatDate(block.end_date, 'MM/dd/yyyy')}</div>
                  <div>{formatMoney(calcPacingBlockBudget(lineItem, block.price))}</div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function calcBudgetFromPrice(lineItem: CombinedLineItem, day: Day): Day {
  return {
    ...day,
    cumulativeTotal: calcPacingBlockBudget(lineItem, day.cumulativeTotal) || 0
  };
}
