import React from 'react';
import Drawer from '../../components/drawer';
import { CombinedStrategy } from '../../store/strategy-combiner';
import { useStore } from '../../store/store';
import { LineItemPacing } from './line-item-pacing';

interface Props {
  strategy: CombinedStrategy;
}

export function PacingDrawer({ strategy }: Props) {
  const [lineItemPacing, setLineItemPacing] = useStore(state => [
    state.lineItemPacing,
    state.setLineItemPacing
  ]);

  function setOpen(_: boolean) {
    setLineItemPacing(lineItemPacing ? { ...lineItemPacing, open: false } : null);
  }

  const lineItem = strategy.line_items.find(lineItem => lineItem.id === lineItemPacing?.lineItemId);
  return (
    <Drawer open={lineItemPacing?.open === true && lineItem !== null} setOpen={setOpen}>
      {lineItem && <LineItemPacing lineItem={lineItem} />}
    </Drawer>
  );
}
