import { z } from 'zod';
import { FinalisedLineItemChange, LineItem, LineItemChangeDraft } from 'shared/src/line-item-types';
import { MediaBuyChanges } from 'shared/src/media-buy-types';
import { Team } from 'shared/src/user-types';
import { ZohoCampaign } from 'shared/src/zoho-types';
import { UTCDate } from '@date-fns/utc';

export const Env = z.union([z.literal('dev'), z.literal('staging'), z.literal('prod')]);

export type Env = z.infer<typeof Env>;

export const stringToUTC = z.string().transform(str => new UTCDate(str));

export const LoginRequest = z.object({
  username: z.string(),
  password: z.string()
});
export type LoginRequest = z.infer<typeof LoginRequest>;

export const Session = z.object({
  user: z.object({
    id: z.number(),
    name: z.string()
  }),
  token: z.string()
});
export type Session = z.infer<typeof Session>;

export const VerifiedToken = z.object({
  email: z.string(),
  name: z.string(),
  exp: z.number()
});
export type VerifiedToken = z.infer<typeof VerifiedToken>;

export const DatabaseCreds = z.object({
  username: z.string(),
  password: z.string()
});
export type DatabaseCreds = z.infer<typeof DatabaseCreds>;

export const SnowflakeCreds = z.object({
  username: z.string(),
  role: z.string(),
  private_key: z.string()
});
export type SnowflakeCreds = z.infer<typeof SnowflakeCreds>;

export const IdInput = z.object({
  id: z.string()
});
export type IdInput = z.infer<typeof IdInput>;

export const Campaign = ZohoCampaign.extend({
  team: Team
});
export type Campaign = z.infer<typeof Campaign>;

export const Strategy = z.object({
  id: z.string(),
  campaign_id: z.string(),
  line_items: z.array(LineItem)
});
export type Strategy = z.infer<typeof Strategy>;

export const DBStrategy = Strategy.omit({ line_items: true });
export type DBStrategy = z.infer<typeof DBStrategy>;

export const FinalisedStrategyChanges = z.object({
  line_items: z.record(FinalisedLineItemChange),
  media_buys: z.record(MediaBuyChanges)
});
export type FinalisedStrategyChanges = z.infer<typeof FinalisedStrategyChanges>;

export const DraftStrategyChanges = z.object({
  line_items: z.record(LineItemChangeDraft),
  media_buys: z.record(MediaBuyChanges)
});
export type DraftStrategyChanges = z.infer<typeof DraftStrategyChanges>;

export const UpdateStrategyInput = z.object({
  strategy_id: z.string(),
  changes: FinalisedStrategyChanges,
  description: z.string()
});

export type UpdateStrategyInput = z.infer<typeof UpdateStrategyInput>;

export type CreateMediaBuyOptions = {
  platformId: number;
  strategyId: string;
  lineItemId: string;
  name: string;
  startDate: Date;
  endDate: Date;
  budget: number;
  cpm: number;
};

export const PacingTypes = ['lifetime', 'monthly', 'custom'] as const;

export const PricingModel = z.union([z.literal('cpm'), z.literal('cpc'), z.literal('cpa')]);
